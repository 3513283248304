import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const GradeBonus = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3636 25.5265C25.3636 24.0448 21.1612 22.8447 15.9773 22.8447M25.3636 25.5265C25.3636 27.0082 21.1612 28.2083 15.9773 28.2083C10.7933 28.2083 6.59091 27.0082 6.59091 25.5265M25.3636 25.5265V32.1466C25.3636 33.6739 21.1612 34.9129 15.9773 34.9129C10.7933 34.9129 6.59091 33.6752 6.59091 32.1466V25.5265M25.3636 25.5265C30.4913 25.5265 34.75 24.203 34.75 22.8447V9.4356M15.9773 22.8447C10.7933 22.8447 6.59091 24.0448 6.59091 25.5265M15.9773 22.8447C10.0531 22.8447 5.25 21.5212 5.25 20.1629V13.4583M15.9773 10.7765C10.0531 10.7765 5.25 11.9766 5.25 13.4583M5.25 13.4583C5.25 14.94 10.0531 16.1401 15.9773 16.1401C15.9773 17.4985 20.3393 18.822 25.4669 18.822C30.5945 18.822 34.75 17.4985 34.75 16.1401M34.75 9.4356C34.75 7.9539 30.5932 6.75378 25.4669 6.75378C20.3406 6.75378 16.1838 7.9539 16.1838 9.4356M34.75 9.4356C34.75 10.9173 30.5932 12.1174 25.4669 12.1174C20.3406 12.1174 16.1838 10.9173 16.1838 9.4356M16.1838 9.4356V23.0673"
        stroke="url(#paint0_linear_1008_11280)"
        strokeWidth="2.68182"
      />
      <path
        d="M25.3636 25.5265C25.3636 24.0448 21.1612 22.8447 15.9773 22.8447M25.3636 25.5265C25.3636 27.0082 21.1612 28.2083 15.9773 28.2083C10.7933 28.2083 6.59091 27.0082 6.59091 25.5265M25.3636 25.5265V32.1466C25.3636 33.6739 21.1612 34.9129 15.9773 34.9129C10.7933 34.9129 6.59091 33.6752 6.59091 32.1466V25.5265M25.3636 25.5265C30.4913 25.5265 34.75 24.203 34.75 22.8447V9.4356M15.9773 22.8447C10.7933 22.8447 6.59091 24.0448 6.59091 25.5265M15.9773 22.8447C10.0531 22.8447 5.25 21.5212 5.25 20.1629V13.4583M15.9773 10.7765C10.0531 10.7765 5.25 11.9766 5.25 13.4583M5.25 13.4583C5.25 14.94 10.0531 16.1401 15.9773 16.1401C15.9773 17.4985 20.3393 18.822 25.4669 18.822C30.5945 18.822 34.75 17.4985 34.75 16.1401M34.75 9.4356C34.75 7.9539 30.5932 6.75378 25.4669 6.75378C20.3406 6.75378 16.1838 7.9539 16.1838 9.4356M34.75 9.4356C34.75 10.9173 30.5932 12.1174 25.4669 12.1174C20.3406 12.1174 16.1838 10.9173 16.1838 9.4356M16.1838 9.4356V23.0673"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="2.68182"
      />
      <g filter="url(#filter0_dd_1008_11280)">
        <circle cx="32.0003" cy="30.5" r="8.76106" fill="url(#paint1_linear_1008_11280)" />
        <circle cx="32.0003" cy="30.5" r="8.76106" fill="black" fillOpacity="0.2" />
        <path
          d="M29.1527 34.6372L32 32.9093L34.8473 34.6372L34.0929 31.4004L36.6239 29.2102L33.2898 28.9425L32 25.8761L30.7102 28.9425L27.3761 29.2102L29.9071 31.4004L29.1527 34.6372ZM32 41.5L28.7389 38.2876H24.2124V33.7611L21 30.5L24.2124 27.2389V22.7124H28.7389L32 19.5L35.2611 22.7124H39.7876V27.2389L43 30.5L39.7876 33.7611V38.2876H35.2611L32 41.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1008_11280"
          x="13"
          y="13.5"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1008_11280" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1008_11280" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1008_11280" result="effect2_dropShadow_1008_11280" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1008_11280" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1008_11280"
          x1="34.75"
          y1="6.75378"
          x2="1.92203"
          y2="11.4002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11280"
          x1="40.7614"
          y1="21.739"
          x2="21.2285"
          y2="24.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

GradeBonus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default GradeBonus;
