import React from 'react';

import { Box, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IconBrandLogo from '../../components/Icons/IconBrandLogo';
import useToggle from '../../hooks/use-toggle';
import LanguageSettings from './LanguageSettings';
import CustomAppBar from './styles';

import { languageItems } from '../../config';
import { TOGGLE_LIST_MENU } from '../../constants/global';

const TopNavigation = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { toggle, toggleClickOpen, toggleClickClose } = useToggle({
    toggleList: TOGGLE_LIST_MENU,
  });

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    toggleClickClose('navLanguageOptions');
  };

  return (
    <CustomAppBar color="primary" position="fixed">
      <Toolbar
        className="toolBar"
        disableGutters
        sx={{
          mr: 2,
          alignItems: 'center',
          display: { xs: 'flex' },
          justifyContent: 'space-between',
          minHeight: '70px !important',
        }}
      >
        <Box display="flex" sx={{ width: '172px' }} onClick={() => navigate('/')}>
          <IconBrandLogo />
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {/* <NavigationMenu pages={pages} /> */}
            <LanguageSettings
              onChangeLanguage={onChangeLanguage}
              options={languageItems}
              toggle={toggle.navLanguageOptions}
              toggleClose={() => toggleClickClose('navLanguageOptions')}
              toggleOpen={(e) => toggleClickOpen(e, 'navLanguageOptions')}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <LanguageSettings
              onChangeLanguage={onChangeLanguage}
              options={languageItems}
              toggle={toggle.navLanguageOptions}
              toggleClose={() => toggleClickClose('navLanguageOptions')}
              toggleOpen={(e) => toggleClickOpen(e, 'navLanguageOptions')}
            />
          </Box>
        </Box>
      </Toolbar>
    </CustomAppBar>
  );
};

export default TopNavigation;
