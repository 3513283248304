import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './languages/en.json';
import kr from './languages/kr.json';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    ...en,
    ...kr,
  },
  fallbackLng: 'en-US',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    // wait: true
    useSuspense: true,
  },
});

export default i18n;
