import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomNavigationAction } from '@mui/material';

import PropTypes from 'prop-types';
import { scrollToSection } from '../../utils/helpers/common';
import CustomBottomNavigation from './styles';

const BottomNavigation = ({ sections }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const renderNavigationItem = (item) => {
    const { Icon, id, label, ref } = item;
    return <BottomNavigationAction key={id} label={t(label)} icon={<Icon />} onClick={() => scrollToSection(ref)} />;
  };

  return (
    <CustomBottomNavigation showLabels value={value} onChange={(event, newValue) => setValue(newValue)}>
      {sections.map((item) => renderNavigationItem(item))}
    </CustomBottomNavigation>
  );
};

BottomNavigation.propTypes = {
  sections: PropTypes.array,
};

export default BottomNavigation;
