import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const EveryDayBonus = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 13.6667H26.6667V10.3333H30V13.6667H31.6667C32.5507 13.6667 33.3986 14.0179 34.0237 14.643C34.6488 15.2681 35 16.116 35 17V40.3333C35 41.2174 34.6488 42.0652 34.0237 42.6904C33.3986 43.3155 32.5507 43.6667 31.6667 43.6667H8.33333C7.44928 43.6667 6.60143 43.3155 5.97631 42.6904C5.35119 42.0652 5 41.2174 5 40.3333V17C5 16.116 5.35119 15.2681 5.97631 14.643C6.60143 14.0179 7.44928 13.6667 8.33333 13.6667H10V10.3333H13.3333V13.6667ZM8.33333 20.3333V40.3333H31.6667V20.3333H8.33333ZM11.6667 25.3333H15V28.6667H11.6667V25.3333ZM18.3333 25.3333H21.6667V28.6667H18.3333V25.3333ZM25 25.3333H28.3333V28.6667H25V25.3333ZM25 32H28.3333V35.3333H25V32ZM18.3333 32H21.6667V35.3333H18.3333V32ZM11.6667 32H15V35.3333H11.6667V32Z"
        fill="url(#paint0_linear_1008_11274)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 13.6667H26.6667V10.3333H30V13.6667H31.6667C32.5507 13.6667 33.3986 14.0179 34.0237 14.643C34.6488 15.2681 35 16.116 35 17V40.3333C35 41.2174 34.6488 42.0652 34.0237 42.6904C33.3986 43.3155 32.5507 43.6667 31.6667 43.6667H8.33333C7.44928 43.6667 6.60143 43.3155 5.97631 42.6904C5.35119 42.0652 5 41.2174 5 40.3333V17C5 16.116 5.35119 15.2681 5.97631 14.643C6.60143 14.0179 7.44928 13.6667 8.33333 13.6667H10V10.3333H13.3333V13.6667ZM8.33333 20.3333V40.3333H31.6667V20.3333H8.33333ZM11.6667 25.3333H15V28.6667H11.6667V25.3333ZM18.3333 25.3333H21.6667V28.6667H18.3333V25.3333ZM25 25.3333H28.3333V28.6667H25V25.3333ZM25 32H28.3333V35.3333H25V32ZM18.3333 32H21.6667V35.3333H18.3333V32ZM11.6667 32H15V35.3333H11.6667V32Z"
        fill="black"
        fillOpacity="0.2"
      />
      <g filter="url(#filter0_dd_1008_11274)">
        <circle cx="31.0003" cy="17" r="8.76106" fill="url(#paint1_linear_1008_11274)" />
        <circle cx="31.0003" cy="17" r="8.76106" fill="black" fillOpacity="0.2" />
        <path
          d="M28.1527 21.1372L31 19.4093L33.8473 21.1372L33.0929 17.9004L35.6239 15.7102L32.2898 15.4425L31 12.3761L29.7102 15.4425L26.3761 15.7102L28.9071 17.9004L28.1527 21.1372ZM31 28L27.7389 24.7876H23.2124V20.2611L20 17L23.2124 13.7389V9.21239H27.7389L31 6L34.2611 9.21239H38.7876V13.7389L42 17L38.7876 20.2611V24.7876H34.2611L31 28Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1008_11274"
          x="12"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1008_11274" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1008_11274" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1008_11274" result="effect2_dropShadow_1008_11274" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1008_11274" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1008_11274"
          x1="35"
          y1="10.3333"
          x2="1.44298"
          y2="14.4137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11274"
          x1="39.7614"
          y1="8.23895"
          x2="20.2285"
          y2="10.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

EveryDayBonus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default EveryDayBonus;
