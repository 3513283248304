export const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  DEFAULT: 'default',
};

export const DATE_FORMAT = {
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_BY_HR: 'YYYY-MM-DD HH:mm',
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss',
  YEAR: 'YYYY',
};

export const LIST_TYPE = {
  IMAGE: 'image',
  LIST: 'list',
  GRID: 'grid',
};

export const LANGUAGE_TYPE = {
  KR: 'kr',
  KO: 'ko',
  EN: 'en',
};

export const LOADING_TYPE = {
  CIRCULAR: 'circular',
  GIF: 'gif',
};

export const MODE = {
  MAIN: 1,
  SECONDARY: 2,
};

export const POSITION = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

export const SEVERITY = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const SIZE = {
  SM: 'small',
  MD: 'medium',
  LG: 'large',
};

export const SORT_KEY = {
  ASC: 'asc',
  DESC: 'desc',
};

export const TEXT_TRANSFORM = {
  UPPERCASE: 'uppercase',
  CAPITALIZE: 'capitalize',
  NORMAL: 'normal',
};

export const VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  FILLED: 'filled',
  TEXT: 'text',
};

export const VARIANT_SKELETON = {
  CIRCULAR: 'circular',
  RECTANGULAR: 'rectangular',
  ROUNDED: 'rounded',
  TEXT: 'text',
};

export const VARIANT_TYPOGRAPHY = {
  BODY1: 'body1',
  BODY2: 'body2',
  CAPTION: 'caption',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p',
  SUBTITLE1: 'subtitle1',
  SUBTITLE2: 'subtitle2',
};
