import PropTypes from 'prop-types';
import React from 'react';
import StyledIconButton from './styles';

const IconButton = ({ Icon, onClick = () => {} }) => {
  return (
    <StyledIconButton onClick={onClick} type="button">
      <Icon />
    </StyledIconButton>
  );
};

IconButton.propTypes = {
  Icon: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default IconButton;
