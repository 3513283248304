import { mapValues } from 'lodash';

import { languages } from '../../config';
import { LANGUAGE_TYPE } from '../../enums';

const formatDate = (date) => {
  // if (date) {
  //   switch (type) {
  //     case DATE_FORMAT.DATE:
  //       return moment(date).format(DATE_FORMAT.DATE);
  //     case DATE_FORMAT.DATE_TIME:
  //       return moment(date).format(DATE_FORMAT.DATE_TIME);
  //     case DATE_FORMAT.DATE_TIME_BY_HR:
  //       return moment(date).format(DATE_FORMAT.DATE_TIME_BY_HR);
  //     case DATE_FORMAT.TIME:
  //       return moment(date).format(DATE_FORMAT.TIME);
  //     case DATE_FORMAT.YEAR:
  //       return moment().format(DATE_FORMAT.YEAR);
  //     default:
  //       return date;
  //   }
  // }
  return date;
};

const getCurrentLang = (language) => {
  const currentLang = languages[language];

  if (currentLang === LANGUAGE_TYPE.KR) return LANGUAGE_TYPE.KO;

  return currentLang;
};

const scrollToSection = (ref) => ref.current?.scrollIntoView({ behavior: 'smooth' });

const formatMapAPIError = (errors) => {
  return mapValues(errors, (e) => e.message);
};

export { formatDate, formatMapAPIError, getCurrentLang, scrollToSection };
