/* eslint-disable */
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoDepositApproval from '../../../components/Icons/function/AutoDepositApproval';
import AutomaticLevelUpSettings from '../../../components/Icons/function/AutomaticLevelUpSettings';
import AutomaticWelcomeText from '../../../components/Icons/function/AutomaticWelcomeText';
import Cashback from '../../../components/Icons/function/Cashback';
import EveryDayBonus from '../../../components/Icons/function/EverydayBonus';
import GradeBonus from '../../../components/Icons/function/GradeBonus';
import Rebate from '../../../components/Icons/function/Rebate';
import SignUpBonus from '../../../components/Icons/function/SignUpBonus';
import UnexpectedEvent from '../../../components/Icons/function/UnexpectedEvent';
import MobileView from './mobile';
import StyledFunction from './styles';
import WebView from './web';

const Function = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const items = [
    {
      icon: <SignUpBonus />,
      title: t('sign-up-bonus'),
      content:
        'Create accumulation conditions for each member and automatic bonuses for the first deposit every day according to the amount and rate set when the conditions are met',
      subContent: 'Automatic check of rolling condition setting when withdrawal',
    },
    {
      icon: <EveryDayBonus />,
      title: t('everyday-bonus'),
      content:
        'Daily automatic deposit bonus based on the amount and rate set when generating bonus conditions for each member level and meeting the conditions (limited number of times can be set) Automatic confirmation of rolling condition setting at withdrawal',
      subContent: '',
    },
    {
      icon: <AutoDepositApproval />,
      title: t('auto-deposit-approval'),
      content:
        'For both general and virtual accounts, bank text messages can be managed on Telegram and automatic deposit approval is possible',
      subContent: '',
    },
  ];

  const items2 = [
    {
      icon: <GradeBonus />,
      title: t('grade-bonus'),
      content: 'Additional bonus deposit (% or deposit amount) by member level',
      subContent: '',
    },
    {
      icon: <AutomaticLevelUpSettings />,
      title: 'automatic-level-up-settings',
      content:
        'Set the level-up conditions for each level and meet the set charging amount / betting amount / lost amount (selected) Automatically change to the next level',
      subContent: '',
    },
    {
      icon: <AutomaticWelcomeText />,
      title: 'automatic-welcome-text',
      content:
        'OTP for sign-up application, can set congratulatory text messages after adjusting the rating after sign-up, and send automatically',
      subContent: '',
    },
  ];

  const items3 = [
    {
      icon: <Cashback />,
      title: 'Cashback',
      content:
        'Automatic Accumulation/deduction (daily, certain days) according to the rate set at the time of deposit/withdraw can be received only when the balance is below the standard amount (paid after deduction after checking withdrawal), and 1x rolling can be set',
      subContent: '',
    },
    {
      icon: <Rebate />,
      title: 'Rebate',
      content:
        'It is also called rolling, and can be set by each game company/member, and the set % can be get according to the amount of betting regardless of winning/losing (receiving accumulation only when accumulation conditions are completed) and 1x rolling can be set',
      subContent: '',
    },
    {
      icon: <UnexpectedEvent />,
      title: 'Unexpected Event',
      content:
        'Create Accumulation conditions for each unexpected time, automatically bonus according to the amount and rate set when meeting the conditions, and automatically check the rolling condition setting when withdrawing!',
      subContent: '',
    },
  ];

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 899.96) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <StyledFunction>
      <Box className="sectionBG">
        <motion.div
          initial={{
            opacity: 0,
            y: -100,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
          // viewport={{ once: true }}
        >
          <Box className="sectionTitle">
            <Typography>
              <span className="spanTextGradient">{t('function')}</span>
            </Typography>
          </Box>
          {/* <Container>
            <Grid2 container spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
              <Box className="sectionDescription">
                <Typography className="sectionDescriptionText">
                  Stay updated with our latest news and press releases, and access our Knowledge Base for essential
                  insights and guides to excel in iGaming.
                </Typography>
              </Box>
            </Grid2>
          </Container> */}
        </motion.div>
        {isMobile ? <MobileView /> : <WebView />}
      </Box>
    </StyledFunction>
  );
};

export default Function;
