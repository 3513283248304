/* eslint-disable */
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid2, Modal, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import bg from '../../.../../../assets/images/home/FunctionBG.png';
import AutoDepositApproval from '../../../components/Icons/function/AutoDepositApproval';
import AutomaticLevelUpSettings from '../../../components/Icons/function/AutomaticLevelUpSettings';
import AutomaticWelcomeText from '../../../components/Icons/function/AutomaticWelcomeText';
import Cashback from '../../../components/Icons/function/Cashback';
import EveryDayBonus from '../../../components/Icons/function/EverydayBonus';
import ExpandMoreIcon from '../../../components/Icons/function/ExpandMoreIcon';
import GradeBonus from '../../../components/Icons/function/GradeBonus';
import Rebate from '../../../components/Icons/function/Rebate';
import SignUpBonus from '../../../components/Icons/function/SignUpBonus';
import UnexpectedEvent from '../../../components/Icons/function/UnexpectedEvent';

const items = [
  {
    icon: <SignUpBonus />,
    title: 'sign-up-bonus',
    content: 'sign-up-bonus-content',
    subContent: 'sign-up-bonus-sub-content',
  },
  {
    icon: <EveryDayBonus />,
    title: 'everyday-bonus',
    content: 'everyday-bonus-content',
    subContent: '',
  },
  {
    icon: <AutoDepositApproval />,
    title: 'auto-deposit-approval',
    content: 'auto-deposit-approval-content',
    subContent: '',
  },
  {
    icon: <GradeBonus />,
    title: 'grade-bonus',
    content: 'grade-bonus-content',
    subContent: '',
  },
  {
    icon: <AutomaticLevelUpSettings />,
    title: 'automatic-level-up-settings',
    content: 'automatic-level-up-settings-content',
    subContent: '',
  },
  {
    icon: <AutomaticWelcomeText />,
    title: 'automatic-welcome-text',
    content: 'automatic-welcome-text-content',
    subContent: '',
  },
  {
    icon: <Cashback />,
    title: 'cash-back',
    content: 'cash-back-content',
    subContent: '',
  },
  {
    icon: <Rebate />,
    title: 'rebate',
    content: 'rebate-content',
    subContent: '',
  },
  {
    icon: <UnexpectedEvent />,
    title: 'unexpected-event',
    content: 'unexpected-event-content',
    subContent: '',
  },
];

const items2 = [
  {
    icon: <GradeBonus />,
    title: 'Grade Bonus',
    content: 'Additional bonus deposit (% or deposit amount) by member level',
    subContent: '',
  },
  {
    icon: <AutomaticLevelUpSettings />,
    title: 'Automatic Level Up <br></br> Settings',
    content:
      'Set the level-up conditions for each level and meet the set charging amount / betting amount / lost amount (selected) Automatically change to the next level',
    subContent: '',
  },
  {
    icon: <AutomaticWelcomeText />,
    title: 'Automatic Welcome <br></br> Text',
    content:
      'OTP for sign-up application, can set congratulatory text messages after adjusting the rating after sign-up, and send automatically',
    subContent: '',
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 399,
  //   height: 252,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'round',
  backgroundImage: `url(${bg})`,
  boxShadow: 24,
  p: 7,
  borderRadius: '12px',
  outline: 'none',
  textAlign: 'center',
  '& .contentDescription': {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  '& .contentSubDescription': {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
};

const ItemCard = ({ icon, title, content, subContent, index, handleOpen }) => (
  <Box className="cardContainer" key={index} onClick={() => handleOpen()}>
    <Box className="content">
      <Box className="functionIcon" key={index}>
        {icon}
      </Box>
      <Typography className="contentTitle">{title}</Typography>
      <span className="expandIcon">
        <ExpandMoreIcon />
      </span>
    </Box>
    <Box className="contentContainer">
      <Typography className="contentDescription">
        {content}
        <br></br>
        <br></br>
        {subContent}
      </Typography>
    </Box>
  </Box>
);

ItemCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  handleModal: PropTypes.func,
};

const MobileView = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState('');
  const [subContent, setSubContent] = useState('');
  const handleOpen = (newContent, newSubContent) => {
    setContent(newContent);
    setSubContent(newSubContent);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal open={open} onClose={handleClose} fullScreen className="modalContainer">
        <Box sx={style}>
          <Box sx={{ position: 'absolute', right: '12px', top: '12px' }} onClick={() => handleClose()}>
            <CloseIcon />
          </Box>
          <Typography className="contentDescription">
            {parse(t(content))}
            <br></br>
            <br></br>
            {t(subContent)}
          </Typography>
        </Box>
      </Modal>
      <motion.div
        initial={{
          opacity: 0,
          x: -100,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 2, // Animation duration
          },
        }}
        // viewport={{ once: true }}
        className="animateContainer"
      >
        <Grid2
          container
          spacing={{ xs: 1, sm: 1, md: 3, lg: 6 }}
          marginBottom={5}
          // justifyContent={'center'}
          // justifyItems={'center'}
          // display={'flex'}
          // className="itemContainer"
          justifyContent={'center'}
        >
          {items.map((item, index) => (
            <Grid2 size={{ xs: 6, sm: 12, md: 12, lg: 4 }} key={index}>
              <ItemCard
                icon={item.icon}
                title={t(item.title)}
                content={t(item.content)}
                subContent={t(item.subContent)}
                key={index}
                handleOpen={() => handleOpen(item.content, item.subContent)}
              />
            </Grid2>
          ))}
        </Grid2>
      </motion.div>
    </>
  );
};

export default MobileView;
