import { styled } from '@mui/material/styles';

export const Slide = styled('div')(({ progress, progressStep, index, progressWithLength, zIndex }) => {
  const moveAmount =
    progressWithLength > index ? (progress - (1 / progressStep) * index) * -500 + progress * 258 : progress * 258;

  return {
    boxSizing: 'border-box',
    width: '100%',
    // padding: '10px',
    // background: '#ccc',
    zIndex: zIndex || 1, // Default zIndex if not provided
    // background: 'transparent',
    transform: `translateX(${moveAmount + index * -86}%)`,
    position: 'absolute', // Ensure pseudo-elements have a reference point
    outline: 'none',
    // justifyContent: 'center',
    // justifyItems: 'center',
    // justifySelf: 'center',
    // display: 'flex',
    // backgroundSize: '100% 100%',
    // backgroundRepeat: 'round',

    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      background: 'transparent',
      position: 'absolute',
      top: 0,
      left: '50%',
      outline: 'none',
    },

    // Example for handling nested images (if needed)
    '& img': {
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '100%',
      transform: 'translateX(-50%)',
      outline: 'none',
    },
  };
});

export default Slide;
