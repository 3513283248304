import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import bg from '../../.../../../assets/images/home/FunctionBG.png';
import frame from '../../.../../../assets/images/home/FunctionFrame.png';

const StyledFunction = styled(Box)(({ theme }) => ({
  minHeight: '90vh',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  padding: '0 50px 0 50px',

  '& .modalContainer': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 399,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },

  '& .MuiSvgIcon-root': {
    fontSize: '85px',
  },

  '& .sectionBG': {
    // position: 'absolute',
    height: '100%',
    width: '100%',
    marginBottom: '200px',

    // '& .videoElement': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   height: '100%',
    //   width: '100%',
    //   objectFit: 'cover',
    //   zIndex: -1,
    // },
  },

  '& .sectionTitle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '60px',
    marginBottom: '80px',
  },
  '& .sectionDescription': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '70px',
  },
  '& .sectionDescription2': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '70px',
    width: '100%',
  },
  '& .sectionDescriptionText': {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '-0.25px',
    padding: '0 100px',
  },
  '& .sectionDescriptionTextGreen': {
    fontWeight: 700,
    fontStyle: 'italic',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '-0.25px',
    textAlign: 'center',
    background:
      'linear-gradient(262.31deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    backgroundClip: 'text',
    color: 'transparent',
  },
  ' & .animateContainer': {
    display: 'flex,',
    justifyContent: 'center',
    textAlign: 'center',
    justifyItems: 'center',
    alignItems: 'center',
  },
  '& .cardContainer': {
    width: '386px',
    height: '128px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#FFFFFF',
    borderRadius: '13px',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'round',
    backgroundImage: `url(${bg})`,
    // transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
    transition: 'transform 0.2s ease',
    // boxShadow:
    //   'var(--sds-size-depth-0) var(--sds-size-depth-025) var(--sds-size-depth-100) var(--sds-size-depth-0) var(--sds-color-black-100)',
    // boxShadow:
    //   'var(--sds-size-depth-0) var(--sds-size-depth-025) var(--sds-size-depth-100) var(--sds-size-depth-0) var(--sds-color-black-200)',
    zIndex: 99,

    '&:before': {
      backgroundRepeat: 'no-repeat, repeat-y',
      backgroundImage: `url(${frame})`,
    },

    '&:hover': {
      // background:
      //   'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, #F5F5F5, #F5F5F5)',
      transform: 'scale(1.25, 1.8)',
      transformOrigin: '50% 50%',
      zIndex: -99,
      // height: '252px',
      // position: 'absolute',
      // padding: '0 10px',
      padding: '0 !important',
      '& .content': {
        transform: 'translateY(-100%) scale(0)',
        textDecoration: 'none',
        opacity: 0,
      },
      '& .contentContainer': {
        transform: 'translateY(0%)',
        opacity: 1,
      },
      '& .functionIcon': {
        // display: 'none',
      },
      '& .contentTitle': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '40px',
        color: '#FFFFFF',
        // textDecoration: 'underline',
        // display: 'none',
      },
      '& .expandIcon': {
        display: 'none',
      },
      '& .contentDescription': {
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        display: 'inline',
        transition: 'none',
        transform: 'scale(0.7, 0.5)',
      },
      '& .contentSubDescription': {
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '18px',
        // lineHeight: '22px',
        color: '#FFFFFF',
        display: 'inline',
        // transform: 'scale(0.769)',
        marginTop: '40px',
      },
    },
  },
  '& .content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    transition: 'opacity 0.2s ease-in-out, transform 0.5s ease-in-out',
    // margin: '0px 50px',
  },
  '& .contentContainer': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    transition: 'opacity 0.2s ease-in-out, transform 0.5s ease-in-out',
    opacity: 0,
    position: 'absolute',
    transform: 'translateY(10%)',
    // margin: '0px 50px',
  },
  '& .contentTitle': {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '40px',
    // backgroundcolor: 'primary',
    // background: 'linear-gradient(146.5deg, #FFFFFF 32.71%, #9EFF3E 88.51%)',
    // backgroundSize: '100%',
    // backgroundRepeat: 'repeat',
    // backgroundClip: 'text',
    // WebkitBackgroundClip: 'text',
    // WebkitTextFillColor: 'transparent',
    alignContent: 'center',
    color: '#ffffff',
    // textAlign: 'left',
  },
  '& .expandIcon': {
    position: 'absolute',
    marginTop: '80px',
  },
  '& .functionIcon': {
    display: 'inline',
    marginRight: '10px',
    marginTop: '5px',
  },
  '& .contentDescription': {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#818181',
    alignContent: 'center',
    margin: '10px 0px',
    textAlign: 'left',
    display: 'none',
  },
  '& .contentSubDescription': {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#818181',
    alignContent: 'center',
    margin: '10px 0px',
    textAlign: 'left',
    display: 'none',
  },
  '& .spanTextWhite': {
    fontSize: '55px',
    fontWeight: '700',
    lineHeight: '61px',
  },
  '& .spanTextGradient': {
    background:
      'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
    fontSize: '55px',
    fontWeight: '700',
    lineHeight: '61px',
  },

  [theme.breakpoints.down('md')]: {
    minHeight: '50vh',
    padding: '0 15px 0 15px',

    '& .sectionBG': {
      marginBottom: '0px',
    },

    '& .sectionTitle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '40px',
      marginBottom: '30px',
    },

    '& .spanTextGradient': {
      background:
        'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
      color: 'transparent',
      WebkitBackgroundClip: 'text',
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '61px',
    },

    '& .cardContainer': {
      width: '193px',
      height: '64px',
      marginBottom: '20px',
      borderRadius: '6.24px',
      padding: '6px',

      '&:hover': {
        padding: '0px 15px',
        // background:
        //   'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, #F5F5F5, #F5F5F5)',
        transform: 'scale(1)',

        '& .content': {
          opacity: 1,
          transform: 'translateY(0)',
        },
        '& .contentTitle': {
          fontWeight: '700',
          fontSize: '12.1px',
          lineHeight: '14.75px',
          display: 'flex',
          textDecoration: 'none',
        },
        '& .expandIcon': {
          position: 'absolute',
          marginTop: '30px',
          '& .MuiSvgIcon-root': {
            height: '4.31px !important',
            width: '8px !important',
          },
          display: 'flex',
        },
        '& .functionIcon': {
          display: 'inline',
          marginRight: '2px',
          marginTop: '5px',
          '& .MuiSvgIcon-root': {
            height: '24.2px !important',
            width: '24.2px !important',
          },
        },
        '& .contentDescription': {
          display: 'none',
          fontSize: '12.1px',
          fontWeight: 500,
        },
        '& .contentSubDescription': {
          display: 'none',
          fontSize: '12.1px',
          fontWeight: 500,
        },
      },
    },

    '& .contentTitle': {
      fontWeight: '700',
      fontSize: '12.1px',
      lineHeight: '14.75px',
    },
    '& .expandIcon': {
      position: 'absolute',
      marginTop: '30px',
      '& .MuiSvgIcon-root': {
        height: '4.31px !important',
        width: '8px !important',
      },
    },
    '& .functionIcon': {
      display: 'flex',
      marginRight: '2px',
      marginTop: '5px',
      '& .MuiSvgIcon-root': {
        height: '24.2px !important',
        width: '24.2px !important',
      },
    },
    '& .contentDescription': {
      fontSize: '12.1px',
      fontWeight: 500,
    },
    '& .contentSubDescription': {
      fontSize: '12.1px',
      fontWeight: 500,
    },
  },
}));

export default StyledFunction;
