import React from 'react';

import { Box } from '@mui/material';
// import { Outlet } from 'react-router';

import BottomNavigation from '../containers/BottomNavigation';
import Footer from '../containers/Footer';
import TopNavigation from '../containers/TopNavigation';
import useSection from '../hooks/use-sections';
import Home from '../pages/Home';
import { useTranslation } from 'react-i18next';

const AppLayout = () => {
  const { sections } = useSection();
  const { i18n } = useTranslation();

  return (
    <Box className={i18n.language === 'en-US' ? 'en' : 'kr'} sx={{ position: 'relative' }}>
      <TopNavigation />

      <Box
        sx={{
          minHeight: 'calc(100vh - 80px)',
          height: '100%',
          width: '100%',
          overflowX: 'visible',
          padding: '0 !important',
          marginTop: '80px',
        }}
      >
        {/* <Outlet /> */}
        <Home sections={sections} />
      </Box>

      <Footer />
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <BottomNavigation sections={sections} />
      </Box>
    </Box>
  );
};

export default AppLayout;
