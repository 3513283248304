import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { S3 } from '../../../config/api';

const StyledSubcription = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${S3}/contactInfoBG.jpg)`,
  backgroundSize: '150% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom center',
  backgroundAttachment: 'fixed',
  minHeight: '60vh',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',

  '& .homeContent': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    color: theme.palette.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    h1: {
      fontSize: '55px',
      background: theme.customGradients.primary,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      boxShadow: theme.palette.boxShadow.primary,
    },
    [theme.breakpoints.down('md')]: {
      h1: {
        fontSize: '28px',
      },
      h3: {
        fontSize: '14px',
      },
    },
    h3: {
      fontSize: '20px',
    },
    '& .subIcon': {
      '& img': {
        maxHeight: '100px',
        maxWidth: '100px',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      background: theme.palette.default.main,
      color: theme.palette.text.secondary,
      borderRadius: '10px',
      height: '70px',
      minWidth: '476px',
      'input::placeholder ': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#717171',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px',
        },
      },
      [theme.breakpoints.down('md')]: {
        height: '49px',
        minWidth: '300px',
        maxWidth: '300px',
        fontSize: '12px',
      },
      '& .MuiInputAdornment-root': {
        margin: '0 15px',
        justifyContent: 'center',
        '& .MuiButton-contained': {
          background: '#1B92D1',
          color: theme.palette.default.main,
          fontWeight: '700',
          fontSize: '18px',
          textTransform: 'none',
          padding: '12px 30px',
          borderRadius: '10px',
          '&:hover': {
            filter: 'brightness(85%)',
          },
          [theme.breakpoints.down('md')]: {
            fontWeight: '700',
            fontSize: '11px',
            padding: '10px 30px',
            borderRadius: '10px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '2em',
          height: '2em',
          [theme.breakpoints.down('md')]: {
            width: '1.5em',
            height: '1.5em',
          },
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        margin: '0 5px 0 15px',
        [theme.breakpoints.down('md')]: {
          margin: '0 5px 0 5px',
        },
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    minHeight: '40vh',
    backgroundSize: '300% 100%',
  },
}));

export default StyledSubcription;
