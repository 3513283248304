import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const Rebate = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1008_11275)">
        <path
          d="M7.85153 31.8184C6.93278 30.2738 6.89645 28.4086 7.75466 26.8297L16.1207 11.4285H21.091L25.7574 19.9539L28.2629 18.5824L24.3472 11.4285H25.7152V8.57148H24.4109L27.1433 2.85703H10.0004L12.982 8.57148H11.4289V11.4285H12.8679L5.24294 25.4652C3.89958 27.9394 3.95661 30.8602 5.39567 33.2793C6.83473 35.6984 9.37458 37.143 12.189 37.143H15.6773V34.2859H12.189C10.3922 34.2855 8.77028 33.3637 7.85153 31.8184ZM14.714 5.71445H22.6101L21.2437 8.57148H16.205L14.714 5.71445Z"
          fill="url(#paint0_linear_1008_11275)"
        />
        <path
          d="M7.85153 31.8184C6.93278 30.2738 6.89645 28.4086 7.75466 26.8297L16.1207 11.4285H21.091L25.7574 19.9539L28.2629 18.5824L24.3472 11.4285H25.7152V8.57148H24.4109L27.1433 2.85703H10.0004L12.982 8.57148H11.4289V11.4285H12.8679L5.24294 25.4652C3.89958 27.9394 3.95661 30.8602 5.39567 33.2793C6.83473 35.6984 9.37458 37.143 12.189 37.143H15.6773V34.2859H12.189C10.3922 34.2855 8.77028 33.3637 7.85153 31.8184ZM14.714 5.71445H22.6101L21.2437 8.57148H16.205L14.714 5.71445Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M35.7145 28.5715V21.4285H18.5719V25.7141H15.7148V32.857H18.5719V37.1426H35.7145V30H32.8574V28.5715H35.7145ZM32.8574 24.2856V25.7141H31.4289V24.2856H32.8574ZM21.4289 24.2856H28.5719V25.7141H21.4289V24.2856ZM18.5719 28.5715H25.7148V30H18.5719V28.5715ZM21.4289 34.2856V32.857H28.5715V34.2856H21.4289ZM32.8574 34.2856H31.4289V32.857H32.8574V34.2856ZM28.5719 30V28.5715H30.0004V30H28.5719Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1008_11275"
          x1="28.2629"
          y1="2.85702"
          x2="1.27459"
          y2="5.40817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <clipPath id="clip0_1008_11275">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

Rebate.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Rebate;
