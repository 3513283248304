import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledScrollUp = styled(Box)(({ theme }) => ({
  '& .floatingButton': {
    position: 'fixed',
    width: '30px',
    height: '55.31px',
    bottom: '80px',
    right: '100px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    color: '#FFF',
    borderRadius: '0px',
    textAlign: 'center',
    padding: '20px 15px',
    zIndex: 99,

    ' .MuiSvgIcon-root': {
      width: '22px !important',
      height: '14px !important',
    },

    '& .default': {
      // position: 'relative',
      transition: 'all 0.5s ease',
      transform: 'translateY(0%)',
      display: 'flex',
    },
    '& .hover': {
      position: 'absolute',
      zIndex: 100,
      transition: 'all 0.5s ease',
      transform: 'translateY(100%)',
      //   transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
      opacity: 0,
      //   color: theme.palette.text.primary,
      display: 'flex',

      ' .MuiSvgIcon-root': {
        width: '30px !important',
        height: '18px !important',
      },
    },

    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)',

      '& .default': {
        transform: 'translateY(-130%)',
        color: theme.palette.text.secondary,
        opacity: 0,
      },
      '& .hover': {
        // display: 'flex',
        transform: 'translateY(0%)',
        color: theme.palette.text.primary,
        opacity: 1,
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .floatingButton': {
      bottom: '150px',
      right: '16px',
      display: 'none',
    },
  },
}));

export default StyledScrollUp;
