import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledTypography = styled(Typography)(
  ({ color, fontWeight = 500, textAlign, textTransform = 'inherit', theme, whiteSpace }) => ({
    color: color || theme.palette.text.primary,
    fontWeight: `${fontWeight} !important`,
    textAlign,
    textTransform: `${textTransform} !important`,
    width: '100%',
    whiteSpace,

    // TODO: change px to em
    '&.MuiTypography-h1': {
      font: theme.typography.h1.font,
    },
    '&.MuiTypography-h2': {
      font: theme.typography.h2.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.h4.font,
      },
    },
    '&.MuiTypography-h3': {
      font: theme.typography.h3.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.h4.font,
      },
    },
    '&.MuiTypography-h4': {
      font: theme.typography.h4.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.h5.font,
      },
    },
    '&.MuiTypography-h5': {
      font: theme.typography.h5.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.h6.font,
      },
    },
    '&.MuiTypography-h6': {
      font: theme.typography.h6.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.p.font,
      },
    },
    '&.MuiTypography-p': {
      font: theme.typography.p.font,

      [theme.breakpoints.down('md')]: {
        font: theme.typography.caption.font,
      },
    },
    '&.MuiTypography-caption': {
      font: theme.typography.caption.font,
    },
  }),
);

export default StyledTypography;
