import PropTypes from 'prop-types';
import React from 'react';

import { motion } from 'framer-motion';
import { isNil } from 'lodash';

const IconClientArea = ({ height, width }) => {
  return (
    <motion.svg
      // initial={{ scale: 1 }}
      // whileHover={{
      //   scale: 1.2,
      // }}
      focusable="false"
      viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 60 61'}
      aria-hidden="true"
      width={width || '60'}
      height={height || '61'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: !isNil(width) ? `${width}px` : 'inherit',
        height: !isNil(height) ? `${height}px` : 'inherit',
      }}
    >
      <path d="M52.5 7.7189H7.5V52.7189H52.5V7.7189Z" fill="white" fillOpacity="0.01" />
      <motion.path
        strokeLinecap="round"
        strokeLinejoin="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        exit={{ pathLength: 0 }}
        whileHover={{ pathLength: 0.1 }} // Shrink path length on hover
        transition={{ duration: 1, ease: 'easeInOut' }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7502 12.4075H11.2502V23.6575H48.7502V12.4075Z"
        stroke="white"
        strokeOpacity="0.8"
        strokeWidth="3.06818"
      />
      <path
        d="M11.25 46.1846L22.6646 34.6526L28.8327 40.5596L36.3733 33.0314L40.5738 37.1264"
        stroke="url(#paint0_linear_517_1598)"
        strokeWidth="3.06818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 46.1846L22.6646 34.6526L28.8327 40.5596L36.3733 33.0314L40.5738 37.1264"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.06818"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.7498 22.8808V47.2558"
        stroke="white"
        strokeOpacity="0.8"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M11.2502 22.8808V36.0058"
        stroke="white"
        strokeOpacity="0.8"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M19.7019 48.0303H48.7499"
        stroke="white"
        strokeOpacity="0.8"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M23.4373 18.0325H43.1248"
        stroke="url(#paint1_linear_517_1598)"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M23.4373 18.0325H43.1248"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M16.8752 18.0293H17.8127"
        stroke="url(#paint2_linear_517_1598)"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <path
        d="M16.8752 18.0293H17.8127"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.06818"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_517_1598"
          x1="40.5738"
          y1="33.0314"
          x2="10.0568"
          y2="42.2233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_517_1598"
          x1="43.1248"
          y1="18.0325"
          x2="40.3573"
          y2="25.3936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_517_1598"
          x1="17.8127"
          y1="18.0293"
          x2="16.7654"
          y2="18.1619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};

IconClientArea.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconClientArea;
