import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  // background: theme.palette.background.default,
  // boxShadow: theme.palette.boxShadow.primary,
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
  backdropFilter: 'blur(100px)', // Optional: adds blur effect
  WebkitBackdropFilter: 'blur(100px)', // Safari compatibility
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)', // TODO: move to theme

  '& .MuiToolbar-root': {
    padding: theme.spacing(0, 9, 0, 12),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 0, 2),
    },
  },
}));

export default CustomAppBar;
