import PropTypes from 'prop-types';
import React from 'react';

import { motion } from 'framer-motion';
import { isNil } from 'lodash';

const IconFunction = ({ height, width }) => (
  <motion.svg
    // initial={{ rotate: '0deg' }}
    // whileHover={{
    //   rotate: '180deg',
    //   transition: { duration: 1, type: 'spring' },
    // }}
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 46 45'}
    aria-hidden="true"
    width={width || '46'}
    height={height || '45'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <g id="iconFunction" clipPath="url(#clip0_457_25211)">
      <g id="alpha">
        <path
          d="M33.062 24.6593C28.4705 24.6593 24.731 28.3987 24.731 32.9903C24.731 37.5818 28.4705 41.3213 33.062 41.3213C37.6587 41.3213 41.393 37.5818 41.393 32.9903C41.393 28.3987 37.6587 24.6593 33.062 24.6593ZM33.062 38.1708C30.2061 38.1708 27.8867 35.8461 27.8867 32.9955C27.8867 30.1449 30.2113 27.8202 33.062 27.8202C35.9179 27.8202 38.2373 30.1449 38.2373 32.9955C38.2373 35.8461 35.9179 38.1708 33.062 38.1708ZM19.2717 24.8381H5.76534C4.89227 24.8381 4.1875 25.5429 4.1875 26.4159V39.9222C4.1875 40.7953 4.89227 41.5001 5.76534 41.5001H19.2717C20.1447 41.5001 20.8495 40.7953 20.8495 39.9222V26.4159C20.8495 25.5429 20.1447 24.8381 19.2717 24.8381ZM17.6938 38.3444H7.34318V27.9938H17.6938V38.3444ZM32.5097 21.9769C32.9147 21.9769 33.3197 21.8244 33.6247 21.5141L41.0353 14.1035C41.3299 13.809 41.4982 13.404 41.4982 12.9885C41.4982 12.573 41.3299 12.168 41.0353 11.8735L33.6247 4.46291C33.3289 4.16743 32.9279 4.00146 32.5097 4.00146C32.0916 4.00146 31.6906 4.16743 31.3947 4.46291L23.9841 11.8735C23.6887 12.1693 23.5227 12.5704 23.5227 12.9885C23.5227 13.4066 23.6887 13.8077 23.9841 14.1035L31.3947 21.5141C31.705 21.8192 32.11 21.9769 32.5097 21.9769ZM32.5097 7.80793L37.6903 12.9885L32.5097 18.1691L27.3292 12.9885L32.5097 7.80793Z"
          fill="white"
        />
      </g>
      <g id="beta">
        <path
          d="M33.062 24.6592C28.4704 24.6592 24.731 28.3987 24.731 32.9902C24.731 37.5817 28.4704 41.3212 33.062 41.3212C37.6587 41.3212 41.3929 37.5817 41.3929 32.9902C41.3929 28.3987 37.6587 24.6592 33.062 24.6592ZM33.062 38.1707C30.2061 38.1707 27.8866 35.8461 27.8866 32.9954C27.8866 30.1448 30.2113 27.8201 33.062 27.8201C35.9178 27.8201 38.2373 30.1448 38.2373 32.9954C38.2373 35.8461 35.9178 38.1707 33.062 38.1707Z"
          fill="url(#paint0_linear_457_25211)"
        />
        <path
          d="M33.062 24.6592C28.4704 24.6592 24.731 28.3987 24.731 32.9902C24.731 37.5817 28.4704 41.3212 33.062 41.3212C37.6587 41.3212 41.3929 37.5817 41.3929 32.9902C41.3929 28.3987 37.6587 24.6592 33.062 24.6592ZM33.062 38.1707C30.2061 38.1707 27.8866 35.8461 27.8866 32.9954C27.8866 30.1448 30.2113 27.8201 33.062 27.8201C35.9178 27.8201 38.2373 30.1448 38.2373 32.9954C38.2373 35.8461 35.9178 38.1707 33.062 38.1707Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <g id="charlie">
        <path
          d="M4.09473 14.4885C4.09473 9.89169 7.83421 6.15747 12.4257 6.15747C17.0225 6.15747 20.7567 9.89695 20.7567 14.4885C20.7567 19.08 17.0172 22.8195 12.4257 22.8195C7.83421 22.8195 4.09473 19.0852 4.09473 14.4885Z"
          fill="url(#paint1_linear_457_25211)"
        />
        <path
          d="M4.09473 14.4885C4.09473 9.89169 7.83421 6.15747 12.4257 6.15747C17.0225 6.15747 20.7567 9.89695 20.7567 14.4885C20.7567 19.08 17.0172 22.8195 12.4257 22.8195C7.83421 22.8195 4.09473 19.0852 4.09473 14.4885Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_457_25211"
        x1="41.3929"
        y1="24.6592"
        x2="22.8189"
        y2="27.1686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#12CF01" />
        <stop offset="0.5" stopColor="#42FF00" />
        <stop offset="1" stopColor="#CCFF00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_457_25211"
        x1="20.7567"
        y1="6.15747"
        x2="2.18264"
        y2="8.66694"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#12CF01" />
        <stop offset="0.5" stopColor="#42FF00" />
        <stop offset="1" stopColor="#CCFF00" />
      </linearGradient>
      <clipPath id="clip0_457_25211">
        <rect width="37.5" height="37.5" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </motion.svg>
);

IconFunction.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconFunction;
