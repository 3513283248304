import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const ExpandMoreIcon = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 12 8'}
    aria-hidden="true"
    width={width || '12'}
    height={height || '8'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.137337 0.895298C0.180865 0.851758 0.232543 0.817219 0.289422 0.793655C0.346301 0.770091 0.407264 0.757963 0.468831 0.757963C0.530397 0.757963 0.591362 0.770091 0.648241 0.793655C0.705119 0.817219 0.756799 0.851758 0.800327 0.895298L6.00001 6.09498L11.1997 0.895298C11.2432 0.851766 11.2949 0.817234 11.3518 0.793674C11.4087 0.770114 11.4696 0.757989 11.5312 0.757989C11.5928 0.757989 11.6537 0.770114 11.7106 0.793674C11.7675 0.817234 11.8192 0.851766 11.8627 0.895298C11.9062 0.938831 11.9408 0.990512 11.9643 1.04739C11.9879 1.10427 12 1.16523 12 1.22679C12 1.28836 11.9879 1.34932 11.9643 1.4062C11.9408 1.46308 11.9062 1.51476 11.8627 1.55829L6.33151 7.08947C6.28798 7.13301 6.2363 7.16755 6.17942 7.19111C6.12254 7.21468 6.06158 7.22681 6.00001 7.22681C5.93845 7.22681 5.87748 7.21468 5.8206 7.19111C5.76372 7.16755 5.71204 7.13301 5.66852 7.08947L0.137337 1.55829C0.0937967 1.51476 0.0592566 1.46308 0.0356922 1.4062C0.0121279 1.34932 0 1.28836 0 1.22679C0 1.16523 0.0121279 1.10426 0.0356922 1.04739C0.0592566 0.990507 0.0937967 0.938827 0.137337 0.895298Z"
        fill="url(#paint0_linear_1035_11621)"
      />
      <path
        d="M0.137337 0.895298C0.180865 0.851758 0.232543 0.817219 0.289422 0.793655C0.346301 0.770091 0.407264 0.757963 0.468831 0.757963C0.530397 0.757963 0.591362 0.770091 0.648241 0.793655C0.705119 0.817219 0.756799 0.851758 0.800327 0.895298L6.00001 6.09498L11.1997 0.895298C11.2432 0.851766 11.2949 0.817234 11.3518 0.793674C11.4087 0.770114 11.4696 0.757989 11.5312 0.757989C11.5928 0.757989 11.6537 0.770114 11.7106 0.793674C11.7675 0.817234 11.8192 0.851766 11.8627 0.895298C11.9062 0.938831 11.9408 0.990512 11.9643 1.04739C11.9879 1.10427 12 1.16523 12 1.22679C12 1.28836 11.9879 1.34932 11.9643 1.4062C11.9408 1.46308 11.9062 1.51476 11.8627 1.55829L6.33151 7.08947C6.28798 7.13301 6.2363 7.16755 6.17942 7.19111C6.12254 7.21468 6.06158 7.22681 6.00001 7.22681C5.93845 7.22681 5.87748 7.21468 5.8206 7.19111C5.76372 7.16755 5.71204 7.13301 5.66852 7.08947L0.137337 1.55829C0.0937967 1.51476 0.0592566 1.46308 0.0356922 1.4062C0.0121279 1.34932 0 1.28836 0 1.22679C0 1.16523 0.0121279 1.10426 0.0356922 1.04739C0.0592566 0.990507 0.0937967 0.938827 0.137337 0.895298Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1035_11621"
          x1="1.78814e-07"
          y1="7.22681"
          x2="12.8162"
          y2="4.01471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

ExpandMoreIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ExpandMoreIcon;
