import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const UnexpectedEvent = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1008_11281)">
        <path
          d="M14.6136 5.63635L19.1023 15.5114L28.9773 20L19.1023 24.4886L14.6136 34.3636L10.125 24.4886L0.25 20L10.125 15.5114L14.6136 5.63635ZM14.6136 14.3084L12.8182 18.2045L8.92204 20L12.8182 21.7954L14.6136 25.6916L16.4091 21.7954L20.3052 20L16.4091 18.2045L14.6136 14.3084Z"
          fill="url(#paint0_linear_1008_11281)"
        />
        <path
          d="M14.6136 5.63635L19.1023 15.5114L28.9773 20L19.1023 24.4886L14.6136 34.3636L10.125 24.4886L0.25 20L10.125 15.5114L14.6136 5.63635ZM14.6136 14.3084L12.8182 18.2045L8.92204 20L12.8182 21.7954L14.6136 25.6916L16.4091 21.7954L20.3052 20L16.4091 18.2045L14.6136 14.3084Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M30.3063 9.69409L32.5685 14.6136L34.8129 9.69409L39.7504 7.43182L34.8129 5.1875L32.5685 0.25L30.3063 5.1875L25.3867 7.43182L30.3063 9.69409Z"
          fill="white"
        />
        <path
          d="M30.3063 34.8305L32.5685 39.75L34.8129 34.8305L39.7504 32.5682L34.8129 30.3239L32.5685 25.3864L30.3063 30.3239L25.3867 32.5682L30.3063 34.8305Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1008_11281"
          x1="28.9773"
          y1="5.63635"
          x2="-3.04667"
          y2="9.96297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <clipPath id="clip0_1008_11281">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

UnexpectedEvent.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default UnexpectedEvent;
