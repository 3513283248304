import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import Typography from '../../../components/Typography';
import { POSITION, TEXT_TRANSFORM, VARIANT_TYPOGRAPHY } from '../../../enums';

const ListItem = ({ item, scrollToSection }) => {
  const { animation, label, Icon, ref } = item;

  return (
    <motion.div
      className="mainMenu"
      onClick={() => scrollToSection(ref)}
      whileHover="hover"
      initial="initial"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {/* Animation Icon */}
      <motion.div
        className="mainMenuIcon"
        variants={{
          initial: animation?.initial,
          hover: animation?.hover,
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon />
      </motion.div>

      {/* Underline Text Animation */}
      <Box className="mainMenuText" mt={1.5} mb={1}>
        <Typography
          fontWeight={600}
          label={label}
          textAlign={POSITION.CENTER}
          textTransform={TEXT_TRANSFORM.UPPERCASE}
          variant={VARIANT_TYPOGRAPHY.P}
        />
      </Box>
    </motion.div>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    animation: PropTypes.shape({
      initial: PropTypes.object,
      hover: PropTypes.object,
    }),
    label: PropTypes.string,
    Icon: PropTypes.node,
    ref: PropTypes.string,
  }),
  scrollToSection: PropTypes.func,
};

export default ListItem;
