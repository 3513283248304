import { motion } from 'framer-motion';
import React from 'react';

const Sections = ({ sections }) =>
  sections.map((section) => (
    <motion.div
      key={section.id}
      ref={section.ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      id={`${section.label}-section`}
    >
      {section.children}
    </motion.div>
  ));

export default Sections;
