import React from 'react';

import { Box } from '@mui/material';

const Services = () => {
  return (
    <Box>
      Services Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat commodi velit itaque architecto sed
      recusandae consectetur magnam corrupti est! Ex vel ut laborum numquam recusandae odit! Accusantium laudantium quia
      repellat.
    </Box>
  );
};

export default Services;
