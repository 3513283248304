import React, { useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import StyledScrollUp from './styles';

import IconUp from '../../../components/Icons/IconUp';
import IconUpGradient from '../../../components/Icons/IconUpGradient';

const ScrollUp = () => {
  //   const theme = useTheme();
  const [isTop, setIsTop] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };
  }, [isTop]);

  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <StyledScrollUp className="scrollUpContainer">
      {isTop && (
        <Box>
          <Button variant="contained" className="floatingButton" onClick={() => scrollToTop()}>
            <div className="hover">
              <IconUp width={'31'} height={'21'} />
            </div>
            <div className="default">
              <IconUpGradient width={'22.22'} height={'11.98'} />
            </div>
          </Button>
        </Box>
      )}
    </StyledScrollUp>
  );
};

export default ScrollUp;
