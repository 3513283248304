import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconUpGradient = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 23 13'}
    aria-hidden="true"
    width={width || '23'}
    height={height || '13'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.2719 11.8922C22.1913 11.9728 22.0956 12.0367 21.9902 12.0804C21.8849 12.124 21.772 12.1465 21.658 12.1465C21.544 12.1465 21.4311 12.124 21.3257 12.0804C21.2204 12.0367 21.1247 11.9728 21.0441 11.8922L11.4149 2.26299L1.78577 11.8922C1.70515 11.9728 1.60945 12.0367 1.50412 12.0804C1.39878 12.124 1.28589 12.1464 1.17188 12.1464C1.05787 12.1464 0.944978 12.124 0.839647 12.0804C0.734316 12.0367 0.638609 11.9728 0.557992 11.8922C0.477375 11.8115 0.413426 11.7158 0.369797 11.6105C0.326167 11.5052 0.303711 11.3923 0.303711 11.2783C0.303711 11.1643 0.326167 11.0514 0.369797 10.946C0.413426 10.8407 0.477375 10.745 0.557992 10.6644L10.801 0.421321C10.8817 0.34069 10.9774 0.276729 11.0827 0.23309C11.188 0.189452 11.3009 0.166992 11.4149 0.166992C11.529 0.166992 11.6418 0.189452 11.7472 0.23309C11.8525 0.276729 11.9482 0.34069 12.0288 0.421321L22.2719 10.6644C22.3525 10.745 22.4165 10.8407 22.4601 10.946C22.5037 11.0514 22.5262 11.1643 22.5262 11.2783C22.5262 11.3923 22.5037 11.5052 22.4601 11.6105C22.4165 11.7158 22.3525 11.8115 22.2719 11.8922Z"
        fill="url(#paint0_linear_2_3405)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_3405"
          x1="22.5262"
          y1="0.166991"
          x2="-1.20784"
          y2="6.1154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

IconUpGradient.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconUpGradient;
