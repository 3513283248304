import PropTypes from 'prop-types';
import React from 'react';

import { Container } from '@mui/material';

import List from '../../../components/List';
import ListItem from './ListItem';
import StyledMainMenu from './styles';

import { scrollToSection } from '../../../utils/helpers/common';

const MainMenu = ({ sections }) => {
  return (
    <StyledMainMenu>
      <Container className="mainMenuContainer">
        <List
          data={sections}
          renderItem={(item) => <ListItem key={item.id} item={item} scrollToSection={scrollToSection} />}
          size={{ md: 2 }}
        />
      </Container>
    </StyledMainMenu>
  );
};

MainMenu.propTypes = {
  sections: PropTypes.array,
};

export default MainMenu;
