import { createTheme } from '@mui/material/styles';
import { DARK } from './dark';
import { LIGHT } from './light';

export const theme = createTheme({
  direction: 'ltr',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          light: LIGHT.primary[50],
          main: LIGHT.primary[100],
          dark: LIGHT.primary[200],
        },
        secondary: {
          light: LIGHT.secondary[50],
          main: LIGHT.secondary[100],
          dark: LIGHT.secondary[200],
        },
        background: {
          default: LIGHT.background.default,
          primary: LIGHT.background.primary,
          secondary: LIGHT.background.secondary,
        },
        text: {
          primary: LIGHT.black,
          secondary: LIGHT.white,
          tertiary: LIGHT.grey[100],
          textDefault: LIGHT.black,
          textPrimary: LIGHT.grey[300],
        },
        success: {
          primary: LIGHT.green[300],
          light: LIGHT.green[50],
          main: LIGHT.green[100],
        },
        error: {
          primary: LIGHT.red[300],
          light: LIGHT.red[50],
          main: LIGHT.red[100],
        },
        info: {
          main: LIGHT.blue[100],
        },
        boxShadow: {
          light: LIGHT.boxShadow[50],
          primary: LIGHT.boxShadow[100],
          secondary: LIGHT.boxShadow[200],
        },
        default: {
          main: LIGHT.white,
        },
        borderColor: {
          primary: LIGHT.borderColor[100],
        },
      },
      customGradients: {
        primary: `linear-gradient(262.31deg, ${LIGHT.gradient.green[50]} 10%, ${LIGHT.gradient.green[100]} 100%, ${LIGHT.gradient.green[200]} 100%)`,
        secondary: `linear-gradient(0deg, ${DARK.gradient.green[200]} 0%, ${DARK.gradient.green[200]} 100%),linear-gradient(262deg, ${DARK.gradient.green[50]} 0%, ${DARK.gradient.green[100]} 100%)`,
      },
    },
    dark: {
      palette: {
        primary: {
          light: DARK.primary[50],
          main: DARK.primary[100],
          dark: DARK.primary[200],
        },
        secondary: {
          light: DARK.secondary[50],
          main: DARK.secondary[100],
          dark: DARK.secondary[200],
        },
        background: {
          default: DARK.background.default,
          primary: DARK.background.primary,
          secondary: DARK.background.secondary,
        },
        text: {
          primary: DARK.white,
          secondary: DARK.black,
          tertiary: DARK.grey[100],
          textDefault: DARK.white,
        },
        success: {
          primary: DARK.green[300],
          light: DARK.green[50],
          main: DARK.green[100],
        },
        error: {
          primary: DARK.red[300],
          light: DARK.red[50],
          main: DARK.red[100],
        },
        info: {
          main: DARK.blue[100],
        },
        boxShadow: {
          light: DARK.boxShadow[50],
          primary: DARK.boxShadow[100],
          secondary: DARK.boxShadow[200],
        },
        default: {
          main: DARK.white,
        },
        borderColor: {
          primary: DARK.borderColor[100],
        },
      },
      customGradients: {
        primary: `linear-gradient(0deg, ${DARK.gradient.green[200]} 0%, ${DARK.gradient.green[200]} 100%),linear-gradient(262deg, ${DARK.gradient.green[50]} 0%, ${DARK.gradient.green[100]} 100%)`,
        secondary: `linear-gradient(262.31deg, ${DARK.gradient.green[50]} 0%, ${DARK.gradient.green[100]} 100%), linear-gradient(0deg, ${DARK.gradient.green[200]}, ${DARK.gradient.green[200]})`,
      },
    },
  },
  typography: {
    fontSize: 14,
    subheading: {
      fontSize: '14px',
    },
    subtitle1: {
      lineHeight: '1.5em',
    },
    title: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '55px',
      lineHeight: '61px',
    },
    h1: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '40px',
      lineHeight: '48.78px',
    },
    h2: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '28px',
      lineHeight: '44px',
    },
    h3: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '24px',
      lineHeight: '40px',
    },
    h4: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '20px',
      lineHeight: '34px',
    },
    h5: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '18px',
      lineHeight: '20px',
    },
    h6: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '15px',
      lineHeight: '24px',
    },
    p: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '14px',
      lineHeight: '19.6px',
    },
    caption: {
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
});
