import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledHome = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  height: '100%',
  position: 'relative',
  overflow: 'visible',

  [theme.breakpoints.down('md')]: {},
}));

export default StyledHome;
