import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import About from '../pages/About';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
import Products from '../pages/Products';
import Providers from '../pages/Providers';
import Services from '../pages/Services';
import AppLayout from './AppLayout';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'products',
        element: <Products />,
      },
      {
        path: 'services',
        element: <Services />,
      },
      {
        path: 'providers',
        element: <Providers />,
      },
      {
        path: 'about-us',
        element: <About />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
]);
