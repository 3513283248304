import PropTypes from 'prop-types';
import React from 'react';

import { motion } from 'framer-motion';
import { isNil } from 'lodash';

// const IconQandAHover = () => {
//   return (
//     <motion.svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="46"
//       height="46"
//       viewBox="0 0 46 46"
//       fill="none"
//       // style={{ transform: 'rotateY(180deg)' }}
//       className="iconQandA-face back"
//     >
//       <path
//         d="M20.4892 21.1242C20.4924 20.4346 20.7208 19.7649 21.1396 19.2169C21.7039 18.4905 22.3477 17.8294 23.0588 17.2458C23.7004 16.6941 24.2887 16.0831 24.8158 15.421C25.1472 14.956 25.319 14.3959 25.3052 13.825C25.3131 13.4754 25.2505 13.1279 25.121 12.803C24.9915 12.4782 24.7979 12.1828 24.5517 11.9345C24.3055 11.6862 24.0118 11.4901 23.6881 11.3579C23.3644 11.2257 23.0174 11.16 22.6678 11.1649C21.9699 11.1582 21.2955 11.4163 20.7805 11.8872C20.1805 12.4653 19.7429 13.1908 19.5116 13.9912L16.6494 12.7424C17.0134 11.3487 17.8176 10.1097 18.9424 9.20986C20.0521 8.37545 21.4115 7.94093 22.7994 7.97692C23.8552 7.96593 24.8983 8.20811 25.8412 8.68317C26.74 9.1345 27.499 9.82183 28.0371 10.6715C28.5826 11.5367 28.8653 12.5416 28.851 13.5643C28.8626 14.4854 28.6147 15.3912 28.1355 16.1779C27.5297 17.1036 26.7958 17.9387 25.9556 18.6583C25.3141 19.2025 24.7106 19.79 24.1494 20.4166C23.8275 20.7852 23.6486 21.2572 23.6453 21.7466C23.6544 22.0465 23.699 22.3443 23.7783 22.6337H20.8523C20.6241 22.1625 20.5002 21.6477 20.4892 21.1242ZM22.4403 30.3917C22.1062 30.4002 21.7739 30.3406 21.4636 30.2164C21.1533 30.0922 20.8715 29.9062 20.6355 29.6696C20.3954 29.4351 20.2063 29.1534 20.0802 28.8424C19.9541 28.5313 19.8936 28.1976 19.9026 27.8621C19.8947 27.5241 19.9556 27.1881 20.0816 26.8744C20.2076 26.5607 20.396 26.2759 20.6355 26.0373C20.8693 25.7965 21.1503 25.6066 21.4608 25.4794C21.7714 25.3522 22.1048 25.2904 22.4403 25.2978C22.7779 25.2906 23.1134 25.3522 23.4263 25.4788C23.7393 25.6055 24.0232 25.7945 24.2608 26.0344C24.4983 26.2743 24.6845 26.5601 24.8081 26.8743C24.9316 27.1885 24.9899 27.5246 24.9793 27.8621C24.9883 28.1975 24.9279 28.5311 24.8021 28.8421C24.6762 29.1531 24.4875 29.4348 24.2478 29.6696C24.0112 29.9061 23.7289 30.0921 23.4182 30.2162C23.1075 30.3404 22.7748 30.4001 22.4403 30.3917Z"
//         fill="white"
//       />
//       <path
//         d="M43.2895 45.6073C43.5773 45.607 43.8616 45.5435 44.1221 45.4211C44.4605 45.2651 44.7468 45.0149 44.9468 44.7005C45.1468 44.3861 45.252 44.0208 45.25 43.6482V5.51381C45.2486 4.25424 44.7476 3.04661 43.8569 2.15596C42.9663 1.26531 41.7587 0.764348 40.4991 0.762939H5.00084C3.74127 0.764348 2.53368 1.26531 1.64304 2.15596C0.752384 3.04661 0.251408 4.25424 0.25 5.51381V32.6675C0.251759 33.9268 0.752911 35.1341 1.64352 36.0244C2.53413 36.9148 3.7415 37.4156 5.00084 37.417H31.564C32.3599 37.4173 33.1304 37.6969 33.7413 38.2071L42.0406 45.1497C42.3905 45.4438 42.8325 45.6057 43.2895 45.6073ZM40.4991 3.42298C41.0534 3.42369 41.5848 3.64417 41.9768 4.03612C42.3687 4.42807 42.5892 4.95951 42.5899 5.51381V42.1399L35.4477 36.1668C34.358 35.2569 32.9837 34.758 31.564 34.757H5.00084C4.44666 34.7566 3.91527 34.5364 3.52328 34.1447C3.1313 33.7529 2.91075 33.2217 2.91004 32.6675V5.51381C2.91075 4.95951 3.13125 4.42807 3.5232 4.03612C3.91515 3.64417 4.44654 3.42369 5.00084 3.42298H40.4991Z"
//         fill="url(#paint0_linear_457_25044)"
//       />
//       <path
//         d="M43.2895 45.6073C43.5773 45.607 43.8616 45.5435 44.1221 45.4211C44.4605 45.2651 44.7468 45.0149 44.9468 44.7005C45.1468 44.3861 45.252 44.0208 45.25 43.6482V5.51381C45.2486 4.25424 44.7476 3.04661 43.8569 2.15596C42.9663 1.26531 41.7587 0.764348 40.4991 0.762939H5.00084C3.74127 0.764348 2.53368 1.26531 1.64304 2.15596C0.752384 3.04661 0.251408 4.25424 0.25 5.51381V32.6675C0.251759 33.9268 0.752911 35.1341 1.64352 36.0244C2.53413 36.9148 3.7415 37.4156 5.00084 37.417H31.564C32.3599 37.4173 33.1304 37.6969 33.7413 38.2071L42.0406 45.1497C42.3905 45.4438 42.8325 45.6057 43.2895 45.6073ZM40.4991 3.42298C41.0534 3.42369 41.5848 3.64417 41.9768 4.03612C42.3687 4.42807 42.5892 4.95951 42.5899 5.51381V42.1399L35.4477 36.1668C34.358 35.2569 32.9837 34.758 31.564 34.757H5.00084C4.44666 34.7566 3.91527 34.5364 3.52328 34.1447C3.1313 33.7529 2.91075 33.2217 2.91004 32.6675V5.51381C2.91075 4.95951 3.13125 4.42807 3.5232 4.03612C3.91515 3.64417 4.44654 3.42369 5.00084 3.42298H40.4991Z"
//         fill="black"
//         fillOpacity="0.2"
//       />
//       <defs>
//         <linearGradient
//           id="paint0_linear_457_25044"
//           x1="0.250001"
//           y1="0.762935"
//           x2="50.4078"
//           y2="7.56308"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#12CF01" />
//           <stop offset="0.5" stopColor="#42FF00" />
//           <stop offset="1" stopColor="#CCFF00" />
//         </linearGradient>
//       </defs>
//     </motion.svg>
//   );
// };

const IconQandA = ({ height, width }) => {
  return (
    <motion.svg
      focusable="false"
      viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 46 45'}
      aria-hidden="true"
      width={width || '46'}
      height={height || '45'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: !isNil(width) ? `${width}px` : 'inherit',
        height: !isNil(height) ? `${height}px` : 'inherit',
      }}
      // className="iconQandA-face front"
    >
      <path
        d="M21.1748 20.7023C21.177 20.2219 21.3361 19.7554 21.6278 19.3737C22.021 18.8677 22.4694 18.4071 22.9648 18.0006C23.4117 17.6163 23.8215 17.1907 24.1887 16.7295C24.4196 16.4055 24.5392 16.0154 24.5296 15.6177C24.5351 15.3742 24.4915 15.1321 24.4013 14.9058C24.3111 14.6795 24.1762 14.4737 24.0047 14.3008C23.8333 14.1278 23.6286 13.9912 23.4032 13.8991C23.1777 13.807 22.9359 13.7613 22.6924 13.7647C22.2063 13.76 21.7365 13.9398 21.3777 14.2678C20.9597 14.6705 20.6549 15.1759 20.4938 15.7335L18.5 14.8636C18.7536 13.8927 19.3138 13.0296 20.0973 12.4028C20.8703 11.8215 21.8172 11.5188 22.7841 11.5439C23.5196 11.5363 24.2461 11.705 24.903 12.0359C25.5291 12.3503 26.0578 12.8291 26.4326 13.421C26.8126 14.0237 27.0096 14.7237 26.9996 15.4361C27.0077 16.0777 26.835 16.7087 26.5012 17.2567C26.0792 17.9015 25.568 18.4833 24.9827 18.9846C24.5358 19.3637 24.1155 19.7729 23.7245 20.2094C23.5002 20.4662 23.3756 20.7949 23.3733 21.1359C23.3797 21.3448 23.4108 21.5522 23.466 21.7538H21.4277C21.2688 21.4256 21.1825 21.0669 21.1748 20.7023ZM22.5339 27.1581C22.3012 27.1639 22.0697 27.1224 21.8536 27.0359C21.6374 26.9494 21.4411 26.8198 21.2767 26.655C21.1094 26.4917 20.9777 26.2955 20.8899 26.0788C20.802 25.8621 20.7599 25.6296 20.7662 25.3959C20.7607 25.1605 20.8031 24.9264 20.8909 24.7079C20.9786 24.4894 21.1099 24.291 21.2767 24.1247C21.4396 23.9571 21.6353 23.8248 21.8516 23.7361C22.0679 23.6475 22.3002 23.6045 22.5339 23.6096C22.7691 23.6046 23.0028 23.6475 23.2208 23.7357C23.4388 23.824 23.6366 23.9556 23.8021 24.1228C23.9675 24.2899 24.0973 24.4889 24.1833 24.7078C24.2694 24.9267 24.31 25.1608 24.3026 25.3959C24.3088 25.6295 24.2668 25.862 24.1791 26.0786C24.0914 26.2953 23.96 26.4915 23.7931 26.655C23.6282 26.8198 23.4316 26.9493 23.2151 27.0358C22.9987 27.1223 22.767 27.1639 22.5339 27.1581Z"
        fill="url(#paint0_linear_457_25035)"
      />
      <path
        d="M21.1748 20.7023C21.177 20.2219 21.3361 19.7554 21.6278 19.3737C22.021 18.8677 22.4694 18.4071 22.9648 18.0006C23.4117 17.6163 23.8215 17.1907 24.1887 16.7295C24.4196 16.4055 24.5392 16.0154 24.5296 15.6177C24.5351 15.3742 24.4915 15.1321 24.4013 14.9058C24.3111 14.6795 24.1762 14.4737 24.0047 14.3008C23.8333 14.1278 23.6286 13.9912 23.4032 13.8991C23.1777 13.807 22.9359 13.7613 22.6924 13.7647C22.2063 13.76 21.7365 13.9398 21.3777 14.2678C20.9597 14.6705 20.6549 15.1759 20.4938 15.7335L18.5 14.8636C18.7536 13.8927 19.3138 13.0296 20.0973 12.4028C20.8703 11.8215 21.8172 11.5188 22.7841 11.5439C23.5196 11.5363 24.2461 11.705 24.903 12.0359C25.5291 12.3503 26.0578 12.8291 26.4326 13.421C26.8126 14.0237 27.0096 14.7237 26.9996 15.4361C27.0077 16.0777 26.835 16.7087 26.5012 17.2567C26.0792 17.9015 25.568 18.4833 24.9827 18.9846C24.5358 19.3637 24.1155 19.7729 23.7245 20.2094C23.5002 20.4662 23.3756 20.7949 23.3733 21.1359C23.3797 21.3448 23.4108 21.5522 23.466 21.7538H21.4277C21.2688 21.4256 21.1825 21.0669 21.1748 20.7023ZM22.5339 27.1581C22.3012 27.1639 22.0697 27.1224 21.8536 27.0359C21.6374 26.9494 21.4411 26.8198 21.2767 26.655C21.1094 26.4917 20.9777 26.2955 20.8899 26.0788C20.802 25.8621 20.7599 25.6296 20.7662 25.3959C20.7607 25.1605 20.8031 24.9264 20.8909 24.7079C20.9786 24.4894 21.1099 24.291 21.2767 24.1247C21.4396 23.9571 21.6353 23.8248 21.8516 23.7361C22.0679 23.6475 22.3002 23.6045 22.5339 23.6096C22.7691 23.6046 23.0028 23.6475 23.2208 23.7357C23.4388 23.824 23.6366 23.9556 23.8021 24.1228C23.9675 24.2899 24.0973 24.4889 24.1833 24.7078C24.2694 24.9267 24.31 25.1608 24.3026 25.3959C24.3088 25.6295 24.2668 25.862 24.1791 26.0786C24.0914 26.2953 23.96 26.4915 23.7931 26.655C23.6282 26.8198 23.4316 26.9493 23.2151 27.0358C22.9987 27.1223 22.767 27.1639 22.5339 27.1581Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M5.63373 41.3703C5.39388 41.3701 5.157 41.3172 4.93991 41.2152C4.65793 41.0851 4.41935 40.8766 4.25268 40.6146C4.08601 40.3526 3.9983 40.0482 4.00002 39.7377V7.95906C4.0012 6.90942 4.41869 5.90306 5.1609 5.16085C5.90311 4.41864 6.90942 4.00117 7.95906 4H37.541C38.5906 4.00117 39.5969 4.41864 40.3391 5.16085C41.0813 5.90306 41.4988 6.90942 41.5 7.95906V30.5871C41.4985 31.6366 41.0809 32.6426 40.3387 33.3846C39.5966 34.1265 38.5904 34.5439 37.541 34.5451H15.405C14.7417 34.5453 14.0996 34.7783 13.5906 35.2034L6.67448 40.989C6.38293 41.2341 6.0146 41.369 5.63373 41.3703ZM7.95906 6.2167C7.49714 6.21729 7.05432 6.40103 6.72769 6.72765C6.40107 7.05427 6.21731 7.49714 6.21673 7.95906V38.4808L12.1686 33.5032C13.0767 32.745 14.2219 32.3292 15.405 32.3284H37.541C38.0028 32.3281 38.4456 32.1446 38.7723 31.8181C39.0989 31.4917 39.2827 31.0489 39.2833 30.5871V7.95906C39.2827 7.49714 39.099 7.05427 38.7723 6.72765C38.4457 6.40103 38.0029 6.21729 37.541 6.2167H7.95906Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_457_25035"
          x1="27"
          y1="11.5425"
          x2="17.4035"
          y2="12.2482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};

IconQandA.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconQandA;
