import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@mui/material';

import Banner from './Banner';
import MainMenu from './MainMenu';
import ScrollUp from './ScrollUp';
import Sections from './Sections';
import StyledHome from './styles';

const Home = ({ sections }) => {
  return (
    <StyledHome className="home">
      <Banner />
      <Box sx={{ position: 'absolute', width: '100%', height: '100%', marginTop: '-180px' }}>
        <MainMenu sections={sections} />
      </Box>
      <Sections sections={sections} />
      <ScrollUp />
    </StyledHome>
  );
};

Home.propTypes = {
  sections: PropTypes.array,
};

export default Home;
