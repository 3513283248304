import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const AutomaticLevelUpSettings = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.865 33.9127C19.5142 34.3195 17.8685 33.6767 17.457 31.9859C17.3578 31.5763 17.1632 31.1959 16.8892 30.8757C16.6152 30.5555 16.2694 30.3046 15.8801 30.1433C15.4907 29.982 15.0688 29.9149 14.6486 29.9475C14.2285 29.98 13.8219 30.1114 13.4621 30.3308C11.0664 31.7902 8.32288 29.0483 9.78235 26.651C10.0014 26.2913 10.1325 25.8851 10.1651 25.4652C10.1976 25.0453 10.1305 24.6236 9.9694 24.2345C9.80828 23.8454 9.55763 23.4998 9.2378 23.2258C8.91798 22.9518 8.53802 22.7572 8.1288 22.6577C5.40238 21.9962 5.40238 18.1178 8.1288 17.4563C8.53838 17.3571 8.91875 17.1625 9.23893 16.8885C9.55912 16.6145 9.81007 16.2687 9.97138 15.8794C10.1327 15.4901 10.1998 15.0681 10.1672 14.648C10.1346 14.2278 10.0033 13.8212 9.78391 13.4614C8.32443 11.0657 11.0664 8.32221 13.4636 9.78168C15.0163 10.7257 17.0285 9.89037 17.457 8.12813C18.1184 5.40171 21.9969 5.40171 22.6583 8.12813C22.7576 8.53771 22.9521 8.91808 23.2261 9.23826C23.5002 9.55844 23.8459 9.8094 24.2353 9.97071C24.6246 10.132 25.0465 10.1991 25.4667 10.1665C25.8869 10.134 26.2934 10.0026 26.6532 9.78323C29.049 8.32376 31.7925 11.0657 30.333 13.463C30.1139 13.8226 29.9828 14.2289 29.9503 14.6488C29.9178 15.0687 29.9848 15.4904 30.1459 15.8795C30.3071 16.2686 30.5577 16.6142 30.8775 16.8882C31.1974 17.1622 31.5773 17.3568 31.9865 17.4563C33.6758 17.8662 34.3186 19.5105 33.9149 20.8597"
        stroke="url(#paint0_linear_1008_11278)"
        strokeWidth="3.10526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.865 33.9127C19.5142 34.3195 17.8685 33.6767 17.457 31.9859C17.3578 31.5763 17.1632 31.1959 16.8892 30.8757C16.6152 30.5555 16.2694 30.3046 15.8801 30.1433C15.4907 29.982 15.0688 29.9149 14.6486 29.9475C14.2285 29.98 13.8219 30.1114 13.4621 30.3308C11.0664 31.7902 8.32288 29.0483 9.78235 26.651C10.0014 26.2913 10.1325 25.8851 10.1651 25.4652C10.1976 25.0453 10.1305 24.6236 9.9694 24.2345C9.80828 23.8454 9.55763 23.4998 9.2378 23.2258C8.91798 22.9518 8.53802 22.7572 8.1288 22.6577C5.40238 21.9962 5.40238 18.1178 8.1288 17.4563C8.53838 17.3571 8.91875 17.1625 9.23893 16.8885C9.55912 16.6145 9.81007 16.2687 9.97138 15.8794C10.1327 15.4901 10.1998 15.0681 10.1672 14.648C10.1346 14.2278 10.0033 13.8212 9.78391 13.4614C8.32443 11.0657 11.0664 8.32221 13.4636 9.78168C15.0163 10.7257 17.0285 9.89037 17.457 8.12813C18.1184 5.40171 21.9969 5.40171 22.6583 8.12813C22.7576 8.53771 22.9521 8.91808 23.2261 9.23826C23.5002 9.55844 23.8459 9.8094 24.2353 9.97071C24.6246 10.132 25.0465 10.1991 25.4667 10.1665C25.8869 10.134 26.2934 10.0026 26.6532 9.78323C29.049 8.32376 31.7925 11.0657 30.333 13.463C30.1139 13.8226 29.9828 14.2289 29.9503 14.6488C29.9178 15.0687 29.9848 15.4904 30.1459 15.8795C30.3071 16.2686 30.5577 16.6142 30.8775 16.8882C31.1974 17.1622 31.5773 17.3568 31.9865 17.4563C33.6758 17.8662 34.3186 19.5105 33.9149 20.8597"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.10526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3984 20.057C15.3984 21.2924 15.8892 22.4771 16.7627 23.3506C17.6362 24.2242 18.821 24.7149 20.0563 24.7149C21.2917 24.7149 22.4764 24.2242 23.35 23.3506C24.2235 22.4771 24.7142 21.2924 24.7142 20.057C24.7142 18.8217 24.2235 17.6369 23.35 16.7634C22.4764 15.8898 21.2917 15.3991 20.0563 15.3991C18.821 15.3991 17.6362 15.8898 16.7627 16.7634C15.8892 17.6369 15.3984 18.8217 15.3984 20.057Z"
        stroke="url(#paint1_linear_1008_11278)"
        strokeWidth="3.10526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3984 20.057C15.3984 21.2924 15.8892 22.4771 16.7627 23.3506C17.6362 24.2242 18.821 24.7149 20.0563 24.7149C21.2917 24.7149 22.4764 24.2242 23.35 23.3506C24.2235 22.4771 24.7142 21.2924 24.7142 20.057C24.7142 18.8217 24.2235 17.6369 23.35 16.7634C22.4764 15.8898 21.2917 15.3991 20.0563 15.3991C18.821 15.3991 17.6362 15.8898 16.7627 16.7634C15.8892 17.6369 15.3984 18.8217 15.3984 20.057Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3.10526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9264 26.2675V35.5833M30.9264 35.5833L35.5843 30.9254M30.9264 35.5833L26.2686 30.9254"
        stroke="white"
        strokeWidth="3.10526"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1008_11278"
          x1="34.0317"
          y1="6.08331"
          x2="2.87678"
          y2="10.2925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11278"
          x1="24.7142"
          y1="15.3991"
          x2="14.3294"
          y2="16.8022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

AutomaticLevelUpSettings.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default AutomaticLevelUpSettings;
