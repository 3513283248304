import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconUp = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 31 21'}
    aria-hidden="true"
    width={width || '31'}
    height={height || '21'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_2_3412)">
        <path
          d="M26.2719 14.8907C26.1913 14.9713 26.0956 15.0353 25.9902 15.0789C25.8849 15.1226 25.772 15.145 25.658 15.145C25.544 15.145 25.4311 15.1226 25.3257 15.0789C25.2204 15.0353 25.1247 14.9713 25.0441 14.8907L15.4149 5.26152L5.78577 14.8907C5.70515 14.9713 5.60945 15.0353 5.50412 15.0789C5.39878 15.1225 5.28589 15.145 5.17188 15.145C5.05787 15.145 4.94498 15.1225 4.83965 15.0789C4.73432 15.0353 4.63861 14.9713 4.55799 14.8907C4.47738 14.8101 4.41343 14.7144 4.3698 14.609C4.32617 14.5037 4.30371 14.3908 4.30371 14.2768C4.30371 14.1628 4.32617 14.0499 4.3698 13.9446C4.41343 13.8392 4.47738 13.7435 4.55799 13.6629L14.801 3.41986C14.8817 3.33923 14.9774 3.27526 15.0827 3.23163C15.188 3.18799 15.3009 3.16553 15.4149 3.16553C15.529 3.16553 15.6418 3.18799 15.7472 3.23163C15.8525 3.27526 15.9482 3.33923 16.0288 3.41986L26.2719 13.6629C26.3525 13.7435 26.4165 13.8392 26.4601 13.9446C26.5037 14.0499 26.5262 14.1628 26.5262 14.2768C26.5262 14.3908 26.5037 14.5037 26.4601 14.609C26.4165 14.7144 26.3525 14.8101 26.2719 14.8907Z"
          fill="url(#paint0_linear_2_3412)"
        />
        <path
          d="M26.2719 14.8907C26.1913 14.9713 26.0956 15.0353 25.9902 15.0789C25.8849 15.1226 25.772 15.145 25.658 15.145C25.544 15.145 25.4311 15.1226 25.3257 15.0789C25.2204 15.0353 25.1247 14.9713 25.0441 14.8907L15.4149 5.26152L5.78577 14.8907C5.70515 14.9713 5.60945 15.0353 5.50412 15.0789C5.39878 15.1225 5.28589 15.145 5.17188 15.145C5.05787 15.145 4.94498 15.1225 4.83965 15.0789C4.73432 15.0353 4.63861 14.9713 4.55799 14.8907C4.47738 14.8101 4.41343 14.7144 4.3698 14.609C4.32617 14.5037 4.30371 14.3908 4.30371 14.2768C4.30371 14.1628 4.32617 14.0499 4.3698 13.9446C4.41343 13.8392 4.47738 13.7435 4.55799 13.6629L14.801 3.41986C14.8817 3.33923 14.9774 3.27526 15.0827 3.23163C15.188 3.18799 15.3009 3.16553 15.4149 3.16553C15.529 3.16553 15.6418 3.18799 15.7472 3.23163C15.8525 3.27526 15.9482 3.33923 16.0288 3.41986L26.2719 13.6629C26.3525 13.7435 26.4165 13.8392 26.4601 13.9446C26.5037 14.0499 26.5262 14.1628 26.5262 14.2768C26.5262 14.3908 26.5037 14.5037 26.4601 14.609C26.4165 14.7144 26.3525 14.8101 26.2719 14.8907Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2_3412"
          x="0.303711"
          y="0.165527"
          width="30.2227"
          height="19.9795"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0470588 0 0 0 0 0.0470588 0 0 0 0 0.0509804 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_3412" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0470588 0 0 0 0 0.0470588 0 0 0 0 0.0509804 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2_3412" result="effect2_dropShadow_2_3412" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_3412" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_2_3412"
          x1="26.5262"
          y1="3.16553"
          x2="2.79216"
          y2="9.11393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

IconUp.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconUp;
