import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const IconPayment = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 26 26'}
    aria-hidden="true"
    width={width || '26'}
    height={height || '26'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0587 0.266113C23.8889 0.266113 25.3854 1.69483 25.4937 3.49779L25.5 3.70743V14.3506C25.5 16.1808 24.0713 17.6773 22.2683 17.7856L22.0587 17.7919H3.94131C2.11112 17.7919 0.614622 16.3632 0.506281 14.5602L0.5 14.3506V3.70743C0.5 1.87723 1.92871 0.380735 3.73168 0.272394L3.94131 0.266113H22.0587ZM23.6219 7.7419H2.37583L2.37708 14.3506C2.37708 15.1605 2.99261 15.8266 3.78138 15.9067L3.94131 15.9148H22.0587C22.8686 15.9148 23.5347 15.2993 23.6148 14.5105L23.6229 14.3506L23.6219 7.7419ZM20.8248 12.1284C21.3432 12.1284 21.7634 12.5486 21.7634 13.0669C21.7634 13.5421 21.4103 13.9347 20.9522 13.9969L20.8248 14.0055H17.6964C17.178 14.0055 16.7578 13.5853 16.7578 13.0669C16.7578 12.5918 17.1109 12.1991 17.569 12.1369L17.6964 12.1284H20.8248ZM22.0587 2.14319H3.94131C3.13141 2.14319 2.46526 2.75872 2.38516 3.54749L2.37708 3.70743L2.37583 5.86482H23.6219L23.6229 3.70743C23.6229 2.89752 23.0074 2.23137 22.2186 2.15127L22.0587 2.14319Z"
        fill="url(#paint0_linear_1407_5189)"
      />
      <path
        d="M22.0587 0.266113C23.8889 0.266113 25.3854 1.69483 25.4937 3.49779L25.5 3.70743V14.3506C25.5 16.1808 24.0713 17.6773 22.2683 17.7856L22.0587 17.7919H3.94131C2.11112 17.7919 0.614622 16.3632 0.506281 14.5602L0.5 14.3506V3.70743C0.5 1.87723 1.92871 0.380735 3.73168 0.272394L3.94131 0.266113H22.0587ZM23.6219 7.7419H2.37583L2.37708 14.3506C2.37708 15.1605 2.99261 15.8266 3.78138 15.9067L3.94131 15.9148H22.0587C22.8686 15.9148 23.5347 15.2993 23.6148 14.5105L23.6229 14.3506L23.6219 7.7419ZM20.8248 12.1284C21.3432 12.1284 21.7634 12.5486 21.7634 13.0669C21.7634 13.5421 21.4103 13.9347 20.9522 13.9969L20.8248 14.0055H17.6964C17.178 14.0055 16.7578 13.5853 16.7578 13.0669C16.7578 12.5918 17.1109 12.1991 17.569 12.1369L17.6964 12.1284H20.8248ZM22.0587 2.14319H3.94131C3.13141 2.14319 2.46526 2.75872 2.38516 3.54749L2.37708 3.70743L2.37583 5.86482H23.6219L23.6229 3.70743C23.6229 2.89752 23.0074 2.23137 22.2186 2.15127L22.0587 2.14319Z"
        fill="black"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1407_5189"
          x1="25.5"
          y1="0.266111"
          x2="-1.86137"
          y2="5.53932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

IconPayment.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconPayment;
