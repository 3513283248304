import { Box, Divider } from '@mui/material';
import Flickity from 'flickity';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const Carousel = ({ children }) => {
  const carousel = useRef(null);
  const root = useRef(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = (progress) => {
      setProgress(progress);
    };

    carousel.current = new Flickity(root.current, {
      imagesLoaded: true,
      autoPlay: 6000,
      fullscreen: true,
    });
    carousel.current.on('scroll', handleScroll);
  }, [children.length]);

  return (
    <>
      {' '}
      <section ref={root} className="slideSection">
        {React.Children.map(children, (child, index) => {
          // return React.cloneElement(<Box className="slideContainer">{child}</Box>, {
          //   progress,
          //   progressStep: children.length - 1,
          //   progressIndex: Math.floor(progress * (children.length - 1)),
          //   progressWithLength: Math.round(progress * (children.length - 1) * 100) / 100,
          //   index,
          //   zIndex: children.length - index,
          // });
          return (
            <Box
              className="slideContainer"
              progress
              progressStep={children.length - 1}
              progressIndex={Math.floor(progress * (children.length - 1))}
              progressWithLength={Math.round(progress * (children.length - 1) * 100) / 100}
              index
              zIndex={children.length - index}
            >
              <div className="slideContent">{child}</div>
            </Box>
          );
        })}
      </section>{' '}
      <Box justifyContent={'center'} display={'flex'} marginTop={60}>
        <Divider
          sx={{
            height: 2,
            width: progress < 0.5 ? '51px' : '12px',
            background:
              'linear-gradient(270deg, #FF3E8F 0%, #FE1D53 100%, #FF3E55 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          }}
        />
        <Divider
          sx={{
            marginLeft: '10px',
            height: 2,
            width: progress < 1 && progress > 0.5 ? '51px' : '12px',
            background: 'linear-gradient(263.53deg, #0495FF 2.76%, #3EC5FF 97.98%)',
          }}
        />
        <Divider
          sx={{
            marginLeft: '10px',
            height: 2,
            width: progress > 1 ? '51px' : '12px',
            background:
              'linear-gradient(262.31deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          }}
        />
      </Box>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
};

export default Carousel;
