import PropTypes from 'prop-types';
import React from 'react';

import { Container, DialogContent, Slide } from '@mui/material';

import StyledDialog from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({ children, fullScreen = false, toggle, toggleClose }) => {
  return (
    <StyledDialog
      aria-describedby="alert-dialog-slide-description"
      fullScreen={fullScreen}
      keepMounted
      open={toggle}
      onClose={toggleClose} // comment this to prevent from closing when click outside modal
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Container>{children}</Container>
      </DialogContent>
    </StyledDialog>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  fullScreen: PropTypes.bool,
  hasClose: PropTypes.bool,
  maxWidth: PropTypes.string,
  renderHeader: PropTypes.string,
  toggle: PropTypes.bool,
  toggleClose: PropTypes.func,
};

export default Modal;
