import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconGlobe = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 22 22'}
    aria-hidden="true"
    width={width || '22'}
    height={height || '22'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <path
      d="M1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9999 1.05005C11.9999 1.05005 14.9999 5.00005 14.9999 11C14.9999 17 11.9999 20.9501 11.9999 20.9501M9.99988 20.9501C9.99988 20.9501 6.99988 17 6.99988 11C6.99988 5.00005 9.99988 1.05005 9.99988 1.05005M1.62988 14.5H20.3699M1.62988 7.50005H20.3699"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

IconGlobe.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconGlobe;
