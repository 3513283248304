import React from 'react';

import { useRef } from 'react';

import IconClientArea from '../components/Icons/menu/IconClientArea';
import IconFunction from '../components/Icons/menu/IconFunction';
import IconProduct from '../components/Icons/menu/IconProduct';
import IconQandA from '../components/Icons/menu/IconQandA';
import IconService from '../components/Icons/menu/IconService';
import Function from '../pages/Home/Function';
import IGamingServices from '../pages/Home/IGamingServices';
import Introduction from '../pages/Home/Introduction';
import LatestNews from '../pages/Home/LatestNews';
import Subscriptions from '../pages/Home/Subscription';

export default function useSection() {
  const introRef = useRef(null);
  const serviceRef = useRef(null);
  const functionRef = useRef(null);
  const qaRef = useRef(null);
  const inquiryRef = useRef(null);

  const sections = [
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/introductions',
      Icon: IconProduct,
      isMenu: true,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <Introduction />,
    },
    {
      id: 'services-page',
      label: 'services',
      path: '/services',
      Icon: IconService,
      isMenu: true,
      animation: {
        initial: { scale: 1 },
        hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: serviceRef,
      children: <IGamingServices />,
    },
    {
      id: 'function-page',
      label: 'function',
      path: '/function',
      Icon: IconFunction,
      isMenu: true,
      animation: {
        initial: { scale: 1 },
        hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: functionRef,
      children: <Function />,
    },
    {
      id: 'q&a-page',
      label: 'q&a',
      path: '/q&a',
      Icon: IconQandA,
      isMenu: true,
      animation: {
        initial: { scale: 1 },
        hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: qaRef,
      children: <LatestNews />,
    },
    {
      id: 'inquiry-page',
      label: 'inquiry',
      path: '/inquiry',
      Icon: IconClientArea,
      isMenu: true,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: inquiryRef,
      children: <Subscriptions />,
    },
  ];

  return { sections };
}
