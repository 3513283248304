/**
 * Add prop here by group
 *
 * make the first word the group then append propName
 * e.g Menu List for products
 *
 * menuProduct: false
 */

export const TOGGLE_LIST_MENU = {
  menuLanguage: null,
  menu: false,
  menuProduct: false,
  menuService: false,
  menuProvider: false,
  menuCompany: false,
  sideMenu: false,
};
