import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const AutoDepositApproval = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.8303 23.306C28.8506 22.3264 27.5219 21.776 26.1364 21.776C24.7509 21.776 23.4221 22.3264 22.4424 23.306C21.4627 24.2857 20.9123 25.6145 20.9123 27C20.9123 28.3855 21.4627 29.7142 22.4424 30.6939C22.9275 31.179 23.5034 31.5638 24.1372 31.8263C24.771 32.0889 25.4503 32.224 26.1364 32.224C26.8224 32.224 27.5017 32.0889 28.1355 31.8263C28.7693 31.5638 29.3452 31.179 29.8303 30.6939C30.3154 30.2088 30.7002 29.6329 30.9627 28.9991C31.2253 28.3653 31.3604 27.686 31.3604 27C31.3604 26.314 31.2253 25.6346 30.9627 25.0008C30.7002 24.367 30.3154 23.7911 29.8303 23.306ZM12.197 16.5454V37.4545H40.0758V16.5454H12.197ZM36.5909 30.4848C35.6674 30.4848 34.7788 30.8507 34.1341 31.5129C33.472 32.1576 33.1061 33.0462 33.1061 33.9697H19.1667C19.1667 33.0462 18.8008 32.1576 18.1386 31.5129C17.4939 30.8507 16.6053 30.4848 15.6818 30.4848V23.5151C16.6053 23.5151 17.4939 23.1492 18.1386 22.4871C18.8008 21.8424 19.1667 20.9538 19.1667 20.0303H33.1061C33.1061 20.9538 33.472 21.8424 34.1341 22.4871C34.7788 23.1492 35.6674 23.5151 36.5909 23.5151V30.4848ZM8.71212 20.0303H5.22727C4.26894 20.0303 3.48485 19.2462 3.48485 18.2879C3.48485 17.3295 4.26894 16.5454 5.22727 16.5454H8.71212V20.0303ZM8.71212 28.7424H3.48485C2.52651 28.7424 1.74242 27.9583 1.74242 27C1.74242 26.0417 2.52651 25.2576 3.48485 25.2576H8.71212V28.7424ZM8.71212 37.4545H1.74242C0.780606 37.4545 0 36.6704 0 35.7121C0 34.7538 0.780606 33.9697 1.74242 33.9697H8.71212V37.4545Z"
        fill="url(#paint0_linear_1008_11276)"
      />
      <path
        d="M29.8303 23.306C28.8506 22.3264 27.5219 21.776 26.1364 21.776C24.7509 21.776 23.4221 22.3264 22.4424 23.306C21.4627 24.2857 20.9123 25.6145 20.9123 27C20.9123 28.3855 21.4627 29.7142 22.4424 30.6939C22.9275 31.179 23.5034 31.5638 24.1372 31.8263C24.771 32.0889 25.4503 32.224 26.1364 32.224C26.8224 32.224 27.5017 32.0889 28.1355 31.8263C28.7693 31.5638 29.3452 31.179 29.8303 30.6939C30.3154 30.2088 30.7002 29.6329 30.9627 28.9991C31.2253 28.3653 31.3604 27.686 31.3604 27C31.3604 26.314 31.2253 25.6346 30.9627 25.0008C30.7002 24.367 30.3154 23.7911 29.8303 23.306ZM12.197 16.5454V37.4545H40.0758V16.5454H12.197ZM36.5909 30.4848C35.6674 30.4848 34.7788 30.8507 34.1341 31.5129C33.472 32.1576 33.1061 33.0462 33.1061 33.9697H19.1667C19.1667 33.0462 18.8008 32.1576 18.1386 31.5129C17.4939 30.8507 16.6053 30.4848 15.6818 30.4848V23.5151C16.6053 23.5151 17.4939 23.1492 18.1386 22.4871C18.8008 21.8424 19.1667 20.9538 19.1667 20.0303H33.1061C33.1061 20.9538 33.472 21.8424 34.1341 22.4871C34.7788 23.1492 35.6674 23.5151 36.5909 23.5151V30.4848ZM8.71212 20.0303H5.22727C4.26894 20.0303 3.48485 19.2462 3.48485 18.2879C3.48485 17.3295 4.26894 16.5454 5.22727 16.5454H8.71212V20.0303ZM8.71212 28.7424H3.48485C2.52651 28.7424 1.74242 27.9583 1.74242 27C1.74242 26.0417 2.52651 25.2576 3.48485 25.2576H8.71212V28.7424ZM8.71212 37.4545H1.74242C0.780606 37.4545 0 36.6704 0 35.7121C0 34.7538 0.780606 33.9697 1.74242 33.9697H8.71212V37.4545Z"
        fill="black"
        fillOpacity="0.2"
      />
      <g filter="url(#filter0_dd_1008_11276)">
        <ellipse cx="40.0926" cy="16.5434" rx="7.11111" ry="5.33333" fill="url(#paint1_linear_1008_11276)" />
        <ellipse cx="40.0926" cy="16.5434" rx="7.11111" ry="5.33333" fill="black" fillOpacity="0.2" />
        <path
          d="M50.2041 16.5434L47.7641 13.7634L48.1041 10.0834L44.4941 9.26343L42.6041 6.08344L39.2041 7.54344L35.8041 6.08344L33.9141 9.26343L30.3041 10.0734L30.6441 13.7534L28.2041 16.5434L30.6441 19.3234L30.3041 23.0134L33.9141 23.8334L35.8041 27.0134L39.2041 25.5434L42.6041 27.0034L44.4941 23.8234L48.1041 23.0034L47.7641 19.3234L50.2041 16.5434ZM37.2041 21.5434L33.2041 17.5434L34.6141 16.1334L37.2041 18.7134L43.7941 12.1234L45.2041 13.5434L37.2041 21.5434Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1008_11276"
          x="20.2041"
          y="0.0834351"
          width="38"
          height="36.93"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1008_11276" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1008_11276" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1008_11276" result="effect2_dropShadow_1008_11276" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1008_11276" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1008_11276"
          x1="40.0758"
          y1="16.5454"
          x2="-2.55575"
          y2="27.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11276"
          x1="47.2037"
          y1="11.21"
          x2="31.5674"
          y2="14.0268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

AutoDepositApproval.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default AutoDepositApproval;
