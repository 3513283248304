import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const IconCost = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 26 26'}
    aria-hidden="true"
    width={width || '26'}
    height={height || '26'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 12.135C0.500071 12.4665 0.631813 12.7844 0.86625 13.0188L12.9812 25.1338C13.2157 25.3681 13.5335 25.4997 13.865 25.4997C14.1965 25.4997 14.5143 25.3681 14.7488 25.1338L25.1338 14.75C25.3681 14.5156 25.4997 14.1977 25.4997 13.8663C25.4997 13.5348 25.3681 13.2169 25.1338 12.9825L13.0188 0.86625C12.7844 0.631813 12.4665 0.500071 12.135 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V12.135ZM3 3H11.6175L22.4825 13.865L13.865 22.4825L3 11.6175V3ZM10.4038 6.9425C10.746 7.28479 10.9792 7.72089 11.0736 8.19566C11.168 8.67043 11.1196 9.16254 10.9343 9.60977C10.7491 10.057 10.4354 10.4392 10.0329 10.7082C9.6304 10.9771 9.1572 11.1207 8.67312 11.1207C8.18905 11.1207 7.71585 10.9771 7.31336 10.7082C6.91087 10.4392 6.59717 10.057 6.41192 9.60977C6.22668 9.16254 6.17822 8.67043 6.27266 8.19566C6.3671 7.72089 6.60021 7.28479 6.9425 6.9425C7.16969 6.71509 7.43947 6.53468 7.73642 6.41159C8.03337 6.2885 8.35167 6.22514 8.67312 6.22514C8.99458 6.22514 9.31288 6.2885 9.60983 6.41159C9.90678 6.53468 10.1766 6.71509 10.4038 6.9425Z"
        fill="url(#paint0_linear_1391_5170)"
      />
      <path
        d="M0.5 12.135C0.500071 12.4665 0.631813 12.7844 0.86625 13.0188L12.9812 25.1338C13.2157 25.3681 13.5335 25.4997 13.865 25.4997C14.1965 25.4997 14.5143 25.3681 14.7488 25.1338L25.1338 14.75C25.3681 14.5156 25.4997 14.1977 25.4997 13.8663C25.4997 13.5348 25.3681 13.2169 25.1338 12.9825L13.0188 0.86625C12.7844 0.631813 12.4665 0.500071 12.135 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V12.135ZM3 3H11.6175L22.4825 13.865L13.865 22.4825L3 11.6175V3ZM10.4038 6.9425C10.746 7.28479 10.9792 7.72089 11.0736 8.19566C11.168 8.67043 11.1196 9.16254 10.9343 9.60977C10.7491 10.057 10.4354 10.4392 10.0329 10.7082C9.6304 10.9771 9.1572 11.1207 8.67312 11.1207C8.18905 11.1207 7.71585 10.9771 7.31336 10.7082C6.91087 10.4392 6.59717 10.057 6.41192 9.60977C6.22668 9.16254 6.17822 8.67043 6.27266 8.19566C6.3671 7.72089 6.60021 7.28479 6.9425 6.9425C7.16969 6.71509 7.43947 6.53468 7.73642 6.41159C8.03337 6.2885 8.35167 6.22514 8.67312 6.22514C8.99458 6.22514 9.31288 6.2885 9.60983 6.41159C9.90678 6.53468 10.1766 6.71509 10.4038 6.9425Z"
        fill="black"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1391_5170"
          x1="25.4997"
          y1="0.499997"
          x2="-2.36891"
          y2="4.26522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

IconCost.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconCost;
