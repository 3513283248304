import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const SignUpBonus = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9997 5C34.2952 14.5933 31.7952 21.5378 27.4997 25.8333C26.7379 26.5951 25.9037 27.2915 24.9973 27.9226L24.9973 31.659L19.9973 36.659L16.9461 31.3196L8.68652 23.0591L3.33301 20L8.33301 15L12.0892 15C12.7146 14.0928 13.4069 13.2594 14.1663 12.5C18.4619 8.20446 25.4063 5.70446 34.9997 5ZM31.2497 8.74833L30.5364 8.85649C24.14 9.86309 19.4864 11.8939 16.5234 14.857C14.7396 16.6408 13.3828 18.9635 12.464 21.8657L12.403 22.0617L17.988 27.6467L18.2643 27.5598C20.8745 26.6835 23.0186 25.4537 24.7251 23.8776L25.1427 23.4763C28.1057 20.5132 30.1366 15.8597 31.1432 9.46326L31.2497 8.74833ZM9.26974 26.0444L5.7342 29.5799L3.37718 27.2229L6.91271 23.6874L9.26974 26.0444ZM12.8053 29.5799L6.91271 35.4725L4.55569 33.1154L10.4482 27.2229L12.8053 29.5799ZM13.9838 30.7584L16.3408 33.1154L12.8053 36.651L10.4482 34.294L13.9838 30.7584Z"
        fill="url(#paint0_linear_1008_11273)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9997 5C34.2952 14.5933 31.7952 21.5378 27.4997 25.8333C26.7379 26.5951 25.9037 27.2915 24.9973 27.9226L24.9973 31.659L19.9973 36.659L16.9461 31.3196L8.68652 23.0591L3.33301 20L8.33301 15L12.0892 15C12.7146 14.0928 13.4069 13.2594 14.1663 12.5C18.4619 8.20446 25.4063 5.70446 34.9997 5ZM31.2497 8.74833L30.5364 8.85649C24.14 9.86309 19.4864 11.8939 16.5234 14.857C14.7396 16.6408 13.3828 18.9635 12.464 21.8657L12.403 22.0617L17.988 27.6467L18.2643 27.5598C20.8745 26.6835 23.0186 25.4537 24.7251 23.8776L25.1427 23.4763C28.1057 20.5132 30.1366 15.8597 31.1432 9.46326L31.2497 8.74833ZM9.26974 26.0444L5.7342 29.5799L3.37718 27.2229L6.91271 23.6874L9.26974 26.0444ZM12.8053 29.5799L6.91271 35.4725L4.55569 33.1154L10.4482 27.2229L12.8053 29.5799ZM13.9838 30.7584L16.3408 33.1154L12.8053 36.651L10.4482 34.294L13.9838 30.7584Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M25 17.5C25 16.1193 23.8807 15 22.5 15C21.1193 15 20 16.1193 20 17.5C20 18.8807 21.1193 20 22.5 20C23.8807 20 25 18.8807 25 17.5Z"
        fill="white"
      />
      <g filter="url(#filter0_dd_1008_11273)">
        <circle cx="30.5003" cy="30" r="8.76106" fill="url(#paint1_linear_1008_11273)" />
        <circle cx="30.5003" cy="30" r="8.76106" fill="black" fillOpacity="0.2" />
        <path
          d="M27.6527 34.1372L30.5 32.4093L33.3473 34.1372L32.5929 30.9004L35.1239 28.7102L31.7898 28.4425L30.5 25.3761L29.2102 28.4425L25.8761 28.7102L28.4071 30.9004L27.6527 34.1372ZM30.5 41L27.2389 37.7876H22.7124V33.2611L19.5 30L22.7124 26.7389V22.2124H27.2389L30.5 19L33.7611 22.2124H38.2876V26.7389L41.5 30L38.2876 33.2611V37.7876H33.7611L30.5 41Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1008_11273"
          x="11.5"
          y="13"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1008_11273" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1008_11273" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1008_11273" result="effect2_dropShadow_1008_11273" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1008_11273" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_1008_11273"
          x1="34.9997"
          y1="5"
          x2="-0.300674"
          y2="9.77044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11273"
          x1="39.2614"
          y1="21.239"
          x2="19.7285"
          y2="23.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

SignUpBonus.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SignUpBonus;
