import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledLatestNews = styled(Box)(({ theme }) => ({
  minHeight: '70vh',
  height: '100%',
  padding: '0 72.5px 60px 72.5px',
  background: theme.palette.secondary.light,

  '& .title-container': {
    paddingRight: '430px',
    paddingLeft: '430px',
    paddingTop: '100px',
    zIndex: 1,
  },

  '& .title': {
    textAlign: 'center',
    fontSize: '55px',
    fontWeight: 700,
    backgroundcolor: 'primary',
    // backgroundImage: theme.customGradients.primary,
    background:
      'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginBottom: '80px',
    lineSpacing: '5px',
  },

  '& .subtitle': {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 400,
    marginTop: 2,
    marginBottom: '45px',
    color: '#fff',
  },

  '& .card': {
    '& .pText': {
      margin: 0,
    },
    '& .accordion': {
      background: '#fff',
      transition: 'background 1s ease-in-out',

      '& .accordion-summary': {
        // background: '#fff',
        // marginBottom: '-6px',
        // transition: 'background 1s ease-in-out',
        height: '88px',
        padding: '20px 40px 20px 40px',
        transition: 'background 1s ease-in-out',
        '& .hover-icon': {
          '& .hovered-icon': {
            display: 'none',
            transition: 'display 0.3s ease',
          },
        },

        '&:hover': {
          // transition: 'background 1s ease-in-out',
          // '& .card-title': {
          //   color: '#fff',
          //   transition: 'color 0.3s ease',
          // },
          // '& .default-icon': {
          //   display: 'none',
          // },
          // '& .hovered-icon': {
          //   display: 'inline',
          // },
        },
      },

      '& :hover': {
        '& .accordion-summary': {
          '& .card-title': {
            color: '#fff',
          },
          '& .default-icon': {
            display: 'none',
          },

          '& .hovered-icon': {
            display: 'inline',
          },

          '& .MuiSvgIcon-root path': {
            fill: '#fff',
          },

          background:
            'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)',
        },
      },
    },

    '& .card-title': {
      fontSize: '18px',
      fontWeight: 600,
      marginLeft: '15px',
      transition: 'color 0.3s ease',
      textTransform: 'capitalize',
    },

    '& .card-content': {
      // height: '628px',
      padding: '30px 40px 40px 30px',
      fontSize: '18px',
      fontWeight: 500,
      color: '#636363',
      // backgroundColor: theme.palette.text.primary,
      backgroundColor: '#ffffff',
      borderRadius: '5px',
      // textTransform: 'capitalize',
    },

    '&:hover': {},
  },

  '& .sub-card': {
    '& .sub-accordion': {
      background: '#fff',
      transition: 'background 1s ease-in-out',

      '& .sub-accordion-summary': {
        // background: '#fff',
        // marginBottom: '-6px',
        // transition: 'background 1s ease-in-out',
        transition: 'background 1s ease-in-out',
        height: '88px',
        '& .hover-icon': {
          '& .hovered-icon': {
            display: 'none',
            transition: 'display 0.3s ease',
          },
        },

        '&:hover': {
          // transition: 'background 1s ease-in-out',
          // '& .card-title': {
          //   color: '#fff',
          //   transition: 'color 0.3s ease',
          // },
          // '& .default-icon': {
          //   display: 'none',
          // },
          // '& .hovered-icon': {
          //   display: 'inline',
          // },
        },
      },

      '& :hover': {
        '& .sub-accordion-summary': {
          '& .sub-card-title': {
            color: '#fff',
          },
          '& .default-icon': {
            display: 'none',
          },

          '& .hovered-icon': {
            display: 'inline',
          },
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)',
        },
      },
    },

    '& .sub-card-title': {
      fontSize: '18px',
      fontWeight: 600,
      marginLeft: '15px',
      transition: 'color 0.3s ease',
      textTransform: 'capitalize',
    },

    '& .sub-card-content': {
      // height: '628px',
      padding: '30px 40px 60px 30px',
      fontSize: '18px',
      fontWeight: 500,
      color: '#636363',
      // backgroundColor: theme.palette.text.primary,
      backgroundColor: '#ffffff',
      borderRadius: '5px',
      // textTransform: 'capitalize',

      '& .pText': {
        margin: 0,
      },
    },
    '&:hover': {},
  },

  [theme.breakpoints.down('md')]: {
    padding: '0 15px 0px 15px',
    minHeight: '50vh',
    height: '100%',
    background: theme.palette.secondary.light,

    // '& .title-container': {
    //   paddingTop: '30px',
    //   zIndex: 1,
    // },

    '& .title-container': {
      paddingRight: '20px',
      paddingLeft: '20px',
      paddingTop: '40px',
    },

    '& .title': {
      fontSize: '28px',
      marginBottom: '30px',
    },
    '& .card': {
      '& .accordion': {
        '& .accordion-summary': {
          height: '60px',
          padding: '20px 10px',
          '& .card-title': {
            fontSize: '14px !important',
            fontWeight: 600,
          },
          '& .MuiSvgIcon-root': {
            height: '20px !important',
            width: '20px !important',
          },
        },
        '& .card-content': {
          fontSize: '14px !important',
          fontWeight: 500,
        },
      },
    },

    '& .sub-card': {
      '& .sub-accordion': {
        '& .sub-accordion-summary': {
          height: '60px',
          padding: '20px 10px',
          '& .sub-card-title': {
            fontSize: '14px !important',
            fontWeight: 600,
          },
          '& .MuiSvgIcon-root': {
            height: '20px !important',
            width: '20px !important',
          },
        },
        '& .sub-card-content': {
          fontSize: '14px !important',
          fontWeight: 500,
        },
      },
    },
  },
}));

export default StyledLatestNews;
