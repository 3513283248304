import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const AutomaticWelcomeText = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 30.4706V7.52942C36 6.55883 35.2059 5.76471 34.2353 5.76471H7.76471C6.79412 5.76471 6 6.55883 6 7.52942V30.4706C6 31.4412 6.79412 32.2353 7.76471 32.2353H34.2353C35.2059 32.2353 36 31.4412 36 30.4706ZM9.52941 9.29412H32.4706V16.3529H9.52941V9.29412ZM10.4118 19.8824C10.9059 19.8824 11.2941 20.2706 11.2941 20.7647C11.2941 21.2588 10.9059 21.6471 10.4118 21.6471C9.91765 21.6471 9.52941 21.2588 9.52941 20.7647C9.52941 20.2706 9.91765 19.8824 10.4118 19.8824ZM13.0588 19.8824H20.1176V21.6471H13.0588V19.8824ZM23.6471 19.8824H32.4706V28.7059H23.6471V19.8824ZM10.4118 23.4118C10.9059 23.4118 11.2941 23.8 11.2941 24.2941C11.2941 24.7882 10.9059 25.1765 10.4118 25.1765C9.91765 25.1765 9.52941 24.7882 9.52941 24.2941C9.52941 23.8 9.91765 23.4118 10.4118 23.4118ZM13.0588 23.4118H20.1176V25.1765H13.0588V23.4118ZM10.4118 26.9412C10.9059 26.9412 11.2941 27.3294 11.2941 27.8235C11.2941 28.3177 10.9059 28.7059 10.4118 28.7059C9.91765 28.7059 9.52941 28.3177 9.52941 27.8235C9.52941 27.3294 9.91765 26.9412 10.4118 26.9412ZM13.0588 26.9412H20.1176V28.7059H13.0588V26.9412Z"
        fill="url(#paint0_linear_1008_11279)"
      />
      <path
        d="M36 30.4706V7.52942C36 6.55883 35.2059 5.76471 34.2353 5.76471H7.76471C6.79412 5.76471 6 6.55883 6 7.52942V30.4706C6 31.4412 6.79412 32.2353 7.76471 32.2353H34.2353C35.2059 32.2353 36 31.4412 36 30.4706ZM9.52941 9.29412H32.4706V16.3529H9.52941V9.29412ZM10.4118 19.8824C10.9059 19.8824 11.2941 20.2706 11.2941 20.7647C11.2941 21.2588 10.9059 21.6471 10.4118 21.6471C9.91765 21.6471 9.52941 21.2588 9.52941 20.7647C9.52941 20.2706 9.91765 19.8824 10.4118 19.8824ZM13.0588 19.8824H20.1176V21.6471H13.0588V19.8824ZM23.6471 19.8824H32.4706V28.7059H23.6471V19.8824ZM10.4118 23.4118C10.9059 23.4118 11.2941 23.8 11.2941 24.2941C11.2941 24.7882 10.9059 25.1765 10.4118 25.1765C9.91765 25.1765 9.52941 24.7882 9.52941 24.2941C9.52941 23.8 9.91765 23.4118 10.4118 23.4118ZM13.0588 23.4118H20.1176V25.1765H13.0588V23.4118ZM10.4118 26.9412C10.9059 26.9412 11.2941 27.3294 11.2941 27.8235C11.2941 28.3177 10.9059 28.7059 10.4118 28.7059C9.91765 28.7059 9.52941 28.3177 9.52941 27.8235C9.52941 27.3294 9.91765 26.9412 10.4118 26.9412ZM13.0588 26.9412H20.1176V28.7059H13.0588V26.9412Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path d="M11.2939 14.5882V11.0588H16.5881V14.5882H11.2939Z" fill="white" />
      <path d="M18.3528 14.5882V11.0588H23.6469V14.5882H18.3528Z" fill="white" />
      <path d="M25.4116 14.5882V11.0588H30.7057V14.5882H25.4116Z" fill="white" />
      <path d="M25.4121 26.9412V23.4118H30.7062V26.9412H25.4121Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1008_11279"
          x1="36"
          y1="5.76471"
          x2="2.72693"
          y2="10.8595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

AutomaticWelcomeText.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default AutomaticWelcomeText;
