import { Box, Button, InputAdornment, SvgIcon, TextField, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../../components/Typography';
import { S3 } from '../../../config/api.js';
import { VARIANT_TYPOGRAPHY } from '../../../enums';
import StyledSubcription from './styles';

const Subscriptions = () => {
  const theme = useTheme();
  const telegramIcon = () => <img src={`${S3}/tg.svg`} />;
  const { t } = useTranslation();

  return (
    <StyledSubcription className="subscriptionsContainer">
      <Box className="homeContent">
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            y: -100,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
        >
          <Box
            mb={5}
            sx={{
              [theme.breakpoints.down('md')]: { maxWidth: '321px' },
            }}
          >
            <Typography
              fontWeight={700}
              label={t('contact information')}
              variant={VARIANT_TYPOGRAPHY.H1}
              sx={{
                textAlign: 'center',
                lineHeight: 'normal !important',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              }}
            />
          </Box>
        </motion.div>
        <Box>
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={telegramIcon} inheritViewBox />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      target="_blank"
                      component="a"
                      rel="noopener noreferrer"
                      href="https://www.t.me/Jadesoftcs"
                      variant="contained"
                    >
                      {t('get in touch')}
                    </Button>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              value="@Jadesoftcs"
              placeholder="@telegram ID"
              variant="outlined"
              color="secondary"
            />
          </motion.div>
        </Box>
      </Box>
    </StyledSubcription>
  );
};

export default Subscriptions;
