/* eslint-disable no-undef */

export const ROOT_URL = process.env.REACT_APP_API_URL;
export const BRAND = process.env.REACT_APP_BRAND;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET;
export const S3 = process.env.REACT_APP_S3;
export const BRAND_TOKEN = process.env.REACT_APP_BRAND_TOKEN;

export function findToken() {
  return localStorage.getItem('AppToken');
}
