import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIGamingServices = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',

  '& .fadeIn': {
    opacity: 1,
    transition: 'opacity 2s ease-in-out',
  },

  '& .fadeOut': {
    opacity: 0,
    transition: 'opacity 2s ease-in-out',
  },

  '& .circleContainer': {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    filter: 'blur(150px)',
  },
  '& .circle1': {
    //yellow green circle
    background: '#57C911',
    height: '280px',
    width: '340px',
    borderRadius: '40% 50% 40% 30%',
    position: 'absolute',
    left: '70%',
    top: '60%',
  },
  '& .circle2': {
    // green circle
    background: '#009736',
    height: '270px',
    width: '270px',
    transform: 'rotate(-180deg)',
    left: '43%',
    top: '27%',
    position: 'absolute',
    borderRadius: '50%',
  },
  '& .circle3': {
    //pink circle
    background: '#D558F4',
    height: '240px',
    width: '280px',
    transform: 'rotate(-180deg)',
    left: '15%',
    top: '70%',
    position: 'absolute',
    borderRadius: '40% 50% 40% 30%',
  },
  '& .circle4': {
    //blue circle
    background: '#93B4FF',
    height: '170px',
    width: '170px',
    transform: 'rotate(-180deg)',
    left: '45%',
    top: '88%',
    position: 'absolute',
    borderRadius: '40% 50% 30% 40%',
  },

  '& .MuiSvgIcon-root': {
    fontSize: '85px',
  },

  '& .sectionBG': {
    position: 'absolute',
    height: '100%',
    width: '100%',

    '& .videoElement': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      zIndex: -1,
    },
  },

  '& .sectionTitle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '200px 0px 90px',
  },
  '& .sectionDescription': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  '& .sectionDescription2': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
    width: '100%',
  },
  '& .sectionDescriptionText': {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '-0.25px',
    padding: '0 100px',
  },
  '& .sectionDescriptionTextGreen': {
    fontWeight: 700,
    fontStyle: 'italic',
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '-0.25px',
    textAlign: 'center',
    background:
      'linear-gradient(262.31deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    backgroundClip: 'text',
    color: 'transparent',
  },
  '& .cardContainer': {
    // width: '386px',
    height: '340px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',

    '&:hover': {
      background:
        'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, #F5F5F5, #F5F5F5)',

      '& .contentTitle': {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '40px',
        color: '#FFFFFF',
        textDecoration: 'underline',
      },
      '& .contentDescription': {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '22px',
        color: '#FFFFFF',
      },
    },
  },
  '& .content': {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
    margin: '0px 50px',
  },
  '& .contentTitle': {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '40px',
    color: '#4B4B4B',
    alignContent: 'center',
    textAlign: 'left',
  },
  '& .contentDescription': {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#818181',
    alignContent: 'center',
    margin: '10px 0px',
    textAlign: 'left',
  },
  '& .spanTextWhite': {
    fontSize: '55px',
    fontWeight: '700',
    lineHeight: '61px',
  },
  '& .spanTextGradient': {
    background:
      'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
    fontSize: '55px',
    fontWeight: '700',
    lineHeight: '61px',
    letterSpacing: '-0.25px',
  },
  '& .notice': {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '34px',
    color: '#fff',
    marginTop: '100px',
  },
  [theme.breakpoints.down('md')]: {
    '& .circleContainer': {
      minHeight: '100vh',
      width: '100%',
    },
    '& .circle1': {
      //yellow green circle
      height: '100px',
      width: '140px',
      position: 'absolute',
      left: '50%',
      top: '60%',
    },
    '& .circle2': {
      // green circle
      height: '50%',
      width: '50%',
    },
    '& .circle3': {
      //pink circle
      height: '100px',
      width: '140px',
      position: 'absolute',
      left: '15%',
      top: '70%',
    },
    '& .circle4': {
      //blue circle
      height: '50%',
      width: '50%',
    },

    '& .sectionBG': {
      position: 'absolute',
      height: '90%',
      width: '100%',

      '& .videoElement': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        zIndex: -1,
      },
    },

    '& .sectionTitle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '30px 0px 20px 0px',
    },

    '& .spanTextGradient': {
      background:
        'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
      color: 'transparent',
      WebkitBackgroundClip: 'text',
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '61px',
    },

    '& .notice': {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '24px',
      color: '#fff',
      marginTop: '30px',
    },

    '& .mobileView': {
      textAlign: 'center',
      '& .flickity-page-dots': {
        display: 'none',
      },

      '& .flickity-enabled': {
        outline: 'none',
      },

      '& .flickity-button': {
        display: 'none',
      },

      '& .bgImage': {
        // width: '172.01px',
        // height: '192.84px',
        // height: '323.29px',
        width: '370px',
      },

      '& .icon': {
        width: '172.01px',
        height: '192.84px',
        position: 'absolute',
        top: '70px',
      },

      // '& .title': {
      //   position: 'absolute',
      //   top: '300px',
      //   left: '150px',
      // },

      '& .provider-en-US': {
        position: 'absolute',
        top: '342px',
        // left: '153px',
        color: '#fff',
        fontWeight: '600',
        fontSize: '13.82px',
        letterSpacing: '0.2em',
      },

      '& .casino': {
        boxShadow: '0px 0px 48px -14px #ff3e84 inset',

        '& .title-en-US': {
          fontSize: '30.72px',
          fontWeight: 600,
          position: 'absolute',
          top: '290px',
          // left: '145px',
          background: 'linear-gradient(263.53deg, #de056d 2.76%, #ff3ea6 97.98%)',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },

        '& .title-ko-KR': {
          fontSize: '30.72px',
          fontWeight: 600,
          position: 'absolute',
          top: '286px',
          // left: '173px',
          background: 'linear-gradient(263.53deg, #de056d 2.76%, #ff3ea6 97.98%)',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },

        '& .provider-ko-KR': {
          position: 'absolute',
          top: '342px',
          // left: '81px',
          color: '#fff',
          fontWeight: '600',
          fontSize: '13.82px',
          letterSpacing: '0.2em',
        },
      },

      '& .slot': {
        '& .title-en-US': {
          fontSize: '30.72px',
          fontWeight: 600,
          position: 'absolute',
          top: '290px',
          // left: '170px',
          background: 'linear-gradient(263.53deg, #0495ff 2.76%, #3ec5ff 97.98%)',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },

        '& .title-ko-KR': {
          fontSize: '30.72px',
          fontWeight: 600,
          position: 'absolute',
          top: '290px',
          // left: '193px',
          background: 'linear-gradient(263.53deg, #0495ff 2.76%, #3ec5ff 97.98%)',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },

        '& .provider-ko-KR': {
          position: 'absolute',
          top: '346px',
          // left: '151px',
          color: '#fff',
          fontWeight: '600',
          fontSize: '13.82px',
          letterSpacing: '0.2em',
        },
      },

      '& .sportsbook': {
        fontSize: '30.72px',
        fontWeight: 600,
        '& .title-en-US': {
          position: 'absolute',
          top: '290px',
          // left: '101px',
          background:
            'linear-gradient(262.31deg, #12cf01 0%, #42ff00 50%, #ccff00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },
        '& .title-ko-KR': {
          position: 'absolute',
          top: '290px',
          // left: '175px',
          background:
            'linear-gradient(262.31deg, #12cf01 0%, #42ff00 50%, #ccff00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          color: 'transparent',
          WebkitBackgroundClip: 'text',
        },

        '& .provider-ko-KR': {
          position: 'absolute',
          top: '346px',
          // left: '101px',
          color: '#fff',
          fontWeight: '600',
          fontSize: '13.82px',
          letterSpacing: '0.2em',
        },
      },
    },

    '& .MuiDialog-scrollPaper': {
      position: 'absolute !important',
      top: '15% !important',
    },
  },
}));

export default StyledIGamingServices;
