import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';
import React from 'react';

import { Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';

import { MODE } from '../../enums';
import Typography from '../Typography';
import StyledList from './styles';

/**
 * {
 *   data: list[]
 *   dense: spacing of list item
 *      TRUE = NO margin on each list item
 *      FALSE = HAS margin on each list item
 *   mode:
 *    MAIN =
 *    SECONDARY =
 *    DEFAULT =
 *   renderItem: function
 * },
 */

const List = ({ data, loading, mode = MODE.MAIN, renderItem, size }) => {
  if (loading) return <Skeleton animation="wave" height={200} variant="rounded" />;
  if (isEmpty(data)) return <Typography label="no data" />;

  return (
    <StyledList container mode={mode}>
      {data.map((item, index) => (
        <Grid key={index} size={size}>
          {renderItem(item)}
        </Grid>
      ))}
    </StyledList>
  );
};

List.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  mode: PropTypes.number,
  renderItem: PropTypes.func.isRequired,
  size: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
  }),
};

export default List;
