/* eslint-disable */
import { Box, Grid2, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoDepositApproval from '../../../components/Icons/function/AutoDepositApproval';
import AutomaticLevelUpSettings from '../../../components/Icons/function/AutomaticLevelUpSettings';
import AutomaticWelcomeText from '../../../components/Icons/function/AutomaticWelcomeText';
import Cashback from '../../../components/Icons/function/Cashback';
import EveryDayBonus from '../../../components/Icons/function/EverydayBonus';
import ExpandMoreIcon from '../../../components/Icons/function/ExpandMoreIcon';
import GradeBonus from '../../../components/Icons/function/GradeBonus';
import Rebate from '../../../components/Icons/function/Rebate';
import SignUpBonus from '../../../components/Icons/function/SignUpBonus';
import UnexpectedEvent from '../../../components/Icons/function/UnexpectedEvent';

const ItemCard = ({ icon, title, content, subContent, index }) => (
  <Box className="cardContainer" key={index}>
    <Box className="content">
      <Box className="functionIcon" key={index}>
        {icon}
      </Box>
      <Typography className="contentTitle">{title}</Typography>
      <span className="expandIcon">
        <ExpandMoreIcon />
      </span>
    </Box>
    <Box className="contentContainer">
      <Typography className="contentDescription">
        {content}
        <br></br>
        <br></br>
        {subContent}
      </Typography>
    </Box>
  </Box>
);

ItemCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  handleModal: PropTypes.func,
};

const WebView = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const items = [
    {
      icon: <SignUpBonus />,
      title: 'sign-up-bonus',
      content: 'sign-up-bonus-content',
      subContent: 'sign-up-bonus-sub-content',
    },
    {
      icon: <EveryDayBonus />,
      title: 'everyday-bonus',
      content: 'everyday-bonus-content',
      subContent: '',
    },
    {
      icon: <AutoDepositApproval />,
      title: 'auto-deposit-approval',
      content: 'auto-deposit-approval-content',
      subContent: '',
    },
  ];

  const items2 = [
    {
      icon: <GradeBonus />,
      title: 'grade-bonus',
      content: 'grade-bonus-content',
      subContent: '',
    },
    {
      icon: <AutomaticLevelUpSettings />,
      title: 'automatic-level-up-settings',
      content: 'automatic-level-up-settings-content',
      subContent: '',
    },
    {
      icon: <AutomaticWelcomeText />,
      title: 'automatic-welcome-text',
      content: 'automatic-welcome-text-content',
      subContent: '',
    },
  ];

  const items3 = [
    {
      icon: <Cashback />,
      title: 'cash-back',
      content: 'cash-back-content',
      subContent: '',
    },
    {
      icon: <Rebate />,
      title: 'rebate',
      content: 'rebate-content',
      subContent: '',
    },
    {
      icon: <UnexpectedEvent />,
      title: 'unexpected-event',
      content: 'unexpected-event-content',
      subContent: '',
    },
  ];

  const handleClickOpen = (id) => {
    filterByIndex(id);
    setOpen(true);
  };
  const handleClose = () => {
    setFilteredData([]);
    setOpen(false);
  };
  const filterByIndex = (excludeIndex) => {
    const filteredData = items.filter((_, index) => index === excludeIndex);
    setFilteredData(filteredData);
  };
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          x: -100,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 2, // Animation duration
          },
        }}
        // viewport={{ once: true }}
        className="animateContainer"
      >
        <Grid2
          container
          spacing={{ xs: 1, sm: 1, md: 3, lg: 6 }}
          marginBottom={5}
          // justifyContent={'center'}
          // justifyItems={'center'}
          // display={'flex'}
          // className="itemContainer"
        >
          {items.map((item, index) => (
            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 4 }} key={index}>
              <ItemCard
                icon={item.icon}
                title={t(item.title)}
                content={parse(t(item.content))}
                subContent={t(item.subContent)}
                key={index}
              />
            </Grid2>
          ))}
        </Grid2>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          x: 100,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 2, // Animation duration
          },
        }}
        // viewport={{ once: true }}
        className="animateContainer"
      >
        <Grid2 container spacing={{ xs: 1, sm: 1, md: 3, lg: 6 }} marginBottom={5}>
          {items2.map((item, index) => (
            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 4 }} key={index}>
              <ItemCard
                icon={item.icon}
                title={t(item.title)}
                content={parse(t(item.content))}
                subContent={t(item.subContent)}
                key={index}
              />
            </Grid2>
          ))}
        </Grid2>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          y: 100,
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 2, // Animation duration
          },
        }}
        // viewport={{ once: true }}
        className="animateContainer"
      >
        <Grid2 container spacing={{ xs: 1, sm: 1, md: 3, lg: 6 }}>
          {items3.map((item, index) => (
            <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 4 }} key={index}>
              <ItemCard
                icon={item.icon}
                title={t(item.title)}
                content={parse(t(item.content))}
                subContent={t(item.subContent)}
                key={index}
              />
            </Grid2>
          ))}
        </Grid2>
      </motion.div>
    </>
  );
};

export default WebView;
