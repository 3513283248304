import { BottomNavigation } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  left: 0,
  height: '70px',
  zIndex: 99,
  background: theme.palette.background.secondary,
  backdropFilter: 'blur(100px)', // Optional: adds blur effect
  WebkitBackdropFilter: 'blur(100px)', // Safari compatibility
  boxShadow: '0px 4px 4px -1px rgba(12, 12, 13, 0.05), 0px 4px 4px -1px rgba(12, 12, 13, 0.0)', // TODO: move to theme

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 1)', // Fully opaque
    backdropFilter: 'blur(100px)', // Optional: adds blur effect
    WebkitBackdropFilter: 'blur(100px)', // Safari compatibility
    zIndex: -1, // Ensure it sits behind the AppBar content
  },

  '& .MuiBottomNavigationAction-root': {
    color: theme.palette.text.primary,
    padding: '8px 0px',

    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px',
    },

    '&.Mui-selected': {
      background: theme.customGradients.primary,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },

    '& .MuiBottomNavigationAction-label': {
      display: 'inline-block',
      marginTop: theme.spacing(0.5),
      font: theme.typography.p.font,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',

      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
        fontWeight: `${600} !important`,
        lineHeight: '14px',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
  },
}));

export default CustomBottomNavigation;
