import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon24Hours from '../../../components/Icons/QandA/Icon24Hours';
import IconContract from '../../../components/Icons/QandA/IconContract';
import IconCost from '../../../components/Icons/QandA/IconCost';
import IconPayment from '../../../components/Icons/QandA/IconPayment';

import StyledLatestNews from './styles';

const LatestNews = () => {
  // const theme = useTheme();
  // const [expanded, setExpanded] = useState(false);
  // const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const [cards, setCards] = useState([
    {
      id: '1',
      icon: <IconCost />,
      title: 'how-much-cost',
      content: 'how-much-cost-content',
      isExpanded: false,
      isHover: false,
    },
    {
      id: '2',
      icon: <IconPayment />,
      title: 'payment',
      content: 'payment-content',
      isExpanded: false,
      isHover: false,
    },
    {
      id: '3',
      icon: <Icon24Hours />,
      title: 'respond-24hrs',
      content: 'respond-24hrs-content',
      isExpanded: false,
      isHover: false,
    },
    {
      id: '4',
      icon: <IconContract />,
      title: 'new-contract',
      content: 'new-contract-content',
      isExpanded: false,
      isHover: false,
    },
  ]);

  const [subCards, setSubCards] = useState([
    {
      id: '5',
      title: 'understanding-ggr',
      content: 'understanding-ggr-content',
      isExpanded: false,
      isHover: false,
    },
    {
      id: '6',
      title: 'additional-cost',
      content: 'additional-cost-content',
      isExpanded: false,
      isHover: false,
    },
  ]);

  function setExpanded(id) {
    let newCards = cards.map((element) => {
      if (element.id == id) {
        return {
          ...element,
          isExpanded: !element.isExpanded,
        };
      } else {
        return element;
      }
    });

    setCards(newCards);
  }

  function setHover(id, value) {
    let newCards = [];
    if (value == 'enter') {
      newCards = cards.map((element) => {
        if (element.id == id) {
          return {
            ...element,
            isHover: value,
          };
        } else {
          return element;
        }
      });
    } else {
      newCards = cards.map((element) => {
        return {
          ...element,
          isHover: false,
        };
      });
    }

    setCards([...newCards]);
  }

  function setExpandedSubcards(id) {
    console.log(id);
    let newCards = subCards.map((element) => {
      if (element.id == id) {
        return {
          ...element,
          isExpanded: !element.isExpanded,
        };
      } else {
        return element;
      }
    });

    console.log(cards);
    console.log(subCards);
    setSubCards([...newCards]);
  }

  function setHoverSubcard(id, value) {
    let newCards = [];
    if (value == 'enter') {
      newCards = subCards.map((element) => {
        if (element.id == id) {
          return {
            ...element,
            isHover: value,
          };
        } else {
          return element;
        }
      });
    } else {
      newCards = subCards.map((element) => {
        return {
          ...element,
          isHover: false,
        };
      });
    }

    setSubCards(newCards);
  }

  function parseText(text) {
    return parse(text);
  }

  return (
    <StyledLatestNews className="latestNewsContainer">
      <Box paddingBottom={10}>
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            y: -100,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
          // viewport={{ once: true }}
        >
          <Box className="title-container">
            <Typography className="title">{t('q-and-a')}</Typography>
            {/* <Typography className="subtitle">
              Stay updated with our latest news and press releases, and access our Knowledge Base for essential insights
              and guides to excel in iGaming.
            </Typography> */}
          </Box>
        </motion.div>
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            y: 150,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
          // viewport={{ once: true }}
        >
          <Box>
            {cards.map((card, index) => (
              <Grid
                key={card.id}
                container
                spacing={{ xs: 2, md: 1.5 }}
                justifyContent={'center'}
                className="accordion-container"
                marginTop={2}
              >
                <Grid size={{ xs: 12, sm: 12, md: 5.5, lg: 5.5 }}>
                  <Box
                    className="card"
                    sx={{
                      borderRadius: '5px',
                      background: card.isExpanded
                        ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                        : '#ffffff',
                    }}
                  >
                    <Accordion
                      key={card.id}
                      sx={{
                        borderRadius: '5px',
                        background: card.isExpanded
                          ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                          : '#ffffff',
                      }}
                      expanded={card.isExpanded}
                      // onClick={() => setExpanded(card.id)}
                      onChange={() => setExpanded(card.id)}
                      className="accordion"
                    >
                      <AccordionSummary
                        expandIcon={
                          card.isExpanded ? (
                            <RemoveIcon sx={{ color: '#FFFFFF' }} />
                          ) : (
                            <AddIcon sx={{ color: card.isHover || card.isExpanded ? '#FFFFFF' : '#4B4B4B' }} />
                          )
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="accordion-summary"
                        sx={{
                          padding: '30px 30px',
                          borderRadius: '5px',
                          background: card.isExpanded
                            ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                            : '#ffffff',
                          // '& :hover': {
                          //   ['& .default' + card.id]: {
                          //     display: 'none',
                          //     // opacity: 1,
                          //   },

                          //   ['& .hover' + card.id]: {
                          //     display: 'flex',
                          //     // opacity: 1,
                          //   },
                          // },
                          '& .MuiSvgIcon-root path': {
                            fill: card.isExpanded && '#fff',
                          },
                        }}
                        onMouseEnter={() => setHover(card.id, 'enter')}
                        onMouseLeave={() => setHover(card.id, 'leave')}
                      >
                        {/* <div className="hover-icon">
                        <div className={'hovered-icon hover' + card.id}>
                          <IconServiceLight />
                        </div>
                        <div className={'default-icon default' + card.id}>
                          <IconService />
                        </div>
                      </div> */}
                        {/* {card.isHover || card.isExpanded ? <IconServiceLight /> : <IconService />} */}
                        {card.icon}
                        <Typography className="card-title" sx={{ color: card.isExpanded ? '#FFFFFF' : '#4B4B4B' }}>
                          {t(card.title)}
                        </Typography>
                      </AccordionSummary>
                      {/* <AccordionDetails className="card-content">{parseText(card.content)}</AccordionDetails> */}
                      <AccordionDetails className="card-content" key={card.id}>
                        {parseText(t(card.content))}
                        <Box marginTop={5}>
                          {index == 0 &&
                            subCards.map((subCard) => {
                              return (
                                <Grid
                                  key={subCard.id}
                                  container
                                  spacing={{ xs: 2, md: 1.5 }}
                                  justifyContent={'center'}
                                  className="accordion-sub-container"
                                  marginTop={2}
                                >
                                  <Grid>
                                    <Box
                                      className="sub-card"
                                      sx={{
                                        borderRadius: '5px',
                                        background: subCard.isExpanded
                                          ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                                          : '#ffffff',
                                      }}
                                    >
                                      <Accordion
                                        key={subCard.id}
                                        sx={{
                                          borderRadius: '5px',
                                          background: subCard.isExpanded
                                            ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                                            : '#ffffff',
                                        }}
                                        expanded={subCard.isExpanded}
                                        // onClick={() => setExpandedSubcards(subCard.id)}
                                        onChange={() => setExpandedSubcards(subCard.id)}
                                        className="sub-accordion"
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                      >
                                        <AccordionSummary
                                          key={subCard.id}
                                          expandIcon={
                                            subCard.isExpanded ? (
                                              <RemoveIcon sx={{ color: '#FFFFFF' }} />
                                            ) : (
                                              <AddIcon
                                                sx={{
                                                  color: subCard.isHover || subCard.isExpanded ? '#FFFFFF' : '#4B4B4B',
                                                }}
                                              />
                                            )
                                          }
                                          aria-controls="panel2-content"
                                          id="panel2-header"
                                          className="sub-accordion-summary"
                                          sx={{
                                            padding: '30px 30px',
                                            borderRadius: '5px',
                                            background: subCard.isExpanded
                                              ? 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%),linear-gradient(262deg, #14FF00 0%, #CCFF00 100%)'
                                              : '#ffffff',
                                          }}
                                          onMouseEnter={() => setHoverSubcard(subCard.id, 'enter')}
                                          onMouseLeave={() => setHoverSubcard(subCard.id, 'leave')}
                                        >
                                          {/* {subCard.isHover || subCard.isExpanded ? <IconServiceLight /> : <IconService />} */}
                                          <Typography
                                            className="sub-card-title"
                                            sx={{ color: subCard.isExpanded ? '#FFFFFF' : '#4B4B4B' }}
                                          >
                                            {t(subCard.title)}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="sub-card-content" key={subCard.id}>
                                          {parseText(t(subCard.content))}
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </motion.div>
      </Box>
    </StyledLatestNews>
  );
};

export default LatestNews;
