import React from 'react';

import { Box } from '@mui/material';

const Contact = () => {
  return (
    <Box>
      Contact Lorem ipsum dolor sit amet consectetur adipisicing elit. Est rem id harum cupiditate sed corrupti
      accusantium, enim eveniet perferendis laboriosam fugit, voluptatum vitae voluptatem recusandae. Beatae earum
      laudantium doloremque a.
    </Box>
  );
};

export default Contact;
