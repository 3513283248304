import React from 'react';

import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import IconBrandLogo from '../../../components/Icons/IconBrandLogo';
import StyledBanner from './styles';

import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const Banner = () => {
  return (
    <StyledBanner>
      <Box className="homeBg">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={`${S3}/homeBG.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box className="homeContent">
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            y: -290,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
          // viewport={{ once: true }}
        >
          <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box>
        </motion.div>

        {/* <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="bannerText" mt={2} mx={8}>
                {bannerText}
              </Box>
              <Box mt={5}>
                <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="Get Started" color={COLOR.DEFAULT} />
              </Box>
            </Box>
          </motion.div>
        </Container> */}
      </Box>
    </StyledBanner>
  );
};

export default Banner;
