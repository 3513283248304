import React, { useState } from 'react';

import { Box, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import IconBrandLogo from '../../../components/Icons/IconBrandLogo';
import StyledIntroduction from './styles';

// const introductionText1 =
//   'Even though the employees are working hard <br/>if sales do not increase, <br/> there may be a problem with the "solution." <br/> <span className="bold">Just changing the solution can increase sales.</span>';

// const introductionText2 =
//   "We didn't start unless it was the best.<br/>We challenge the best of the system with 20 years of accumulated know-how.";

// const introductionText3 = 'Let me introduce <span>Jade Soft</span>';

const Introduction = () => {
  const { i18n, t } = useTranslation();
  const [changeOpacity, setChangeOpacity] = useState(false);

  return (
    <StyledIntroduction>
      <Box className={`blobs ${changeOpacity ? 'fadein' : 'fadeout'}`}>
        <Box className="blob a" />
        <Box className="blob b" />
      </Box>

      {/* <Box className="introductionBg">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={`${S3}/introductionBG.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box> */}
      <Box className="introductionContent">
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            y: -100,
          }}
          whileInView={{
            opacity: 1,
            y: 0, // Slide in to its original position
            transition: {
              duration: 2, // Animation duration
            },
          }}
          // viewport={{ once: true }}
        >
          {i18n.language === 'en-US' ? (
            <Box className="sectionTitle">
              <Typography>
                <span className="spanTextGradient">Introduction</span>
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box className="iconLogo">
            <IconBrandLogo />
          </Box>
        </motion.div>

        <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            onViewportEnter={() => setChangeOpacity(true)}
            onViewportLeave={() => setChangeOpacity(false)}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box
                className="introductionText"
                sx={{ fontSize: i18n.language === 'en-US' ? '21px!important' : 'auto' }}
              >
                <span dangerouslySetInnerHTML={{ __html: t('introductionText1') }}></span>
              </Box>
              <Box
                className="introductionText"
                sx={{ fontSize: i18n.language === 'en-US' ? '21px!important' : 'auto' }}
              >
                <p dangerouslySetInnerHTML={{ __html: t('introductionText2') }}></p>
              </Box>
              <Box
                className="introductionText"
                sx={{ fontSize: i18n.language === 'en-US' ? '21px!important' : 'auto' }}
              >
                <p className="style" dangerouslySetInnerHTML={{ __html: t('introductionText3') }}></p>
              </Box>
            </Box>
          </motion.div>
        </Container>
      </Box>
    </StyledIntroduction>
  );
};

export default Introduction;
