import { Box, Container, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Dialog';

import StyledIGamingServices from './styles';

// Components
import Carousel from '../../../components/Carousel';

// Styles
import { Slide } from '../../../components/Carousel/styles';

// const introductionText1 =
//   'Even though the employees are working hard <br/>if sales do not increase, <br/> there may be a problem with the "solution." <br/> <span className="bold">Just changing the solution can increase sales.</span>';

// const introductionText2 =
//   "We didn't start unless it was the best.<br/>We challenge the best of the system with 20 years of accumulated know-how.";

// const introductionText3 = 'Let me introduce <span>Jade Soft</span>';

// const sliderVariants = {
//   incoming: (direction) => ({
//     x: direction > 0 ? '100%' : '-100%',
//     scale: 1.2,
//     opacity: 0,
//   }),
//   active: { x: 0, scale: 1, opacity: 1 },
//   exit: (direction) => ({
//     x: direction > 0 ? '-100%' : '100%',
//     scale: 1,
//     opacity: 0.2,
//   }),
// };

// const sliderTransition = {
//   duration: 1,
//   ease: [0.56, 0.03, 0.12, 1.04],
// };

const items = [
  {
    title: 'CASINO',
    bgImage: '/images/casino-bg.svg',
    providers: [
      'evolution-gaming.svg',
      'pragmatic-play-casino.svg',
      'microgaming.svg',
      'mg-live.svg',
      'imagine-live.svg',
      'asia-gaming.svg',
      'ho-gaming.svg',
      'better-live.svg',
      'betgames.svg',
      'pretty-gaming.svg',
      'wm-casino.svg',
      'dream-gaming.svg',
      'via-casino.svg',
      'sexy-gaming.svg',
      'vivo.svg',
      'infinity.svg',
      'world-entertainment.svg',
      'yeebet-gaming.svg',
    ],
  },
  {
    title: 'SLOT',
    bgImage: '/images/slot-bg.svg',
    providers: [
      'bolebit.svg',
      'red-tiger.svg',
      'amigo-gaming.svg',
      'joker.svg',
      'jili.svg',
      'simpleplay.svg',
      'fa-chai.svg',
      'habanero.svg',
      'ka-gaming.svg',
      'pragmatic-play.svg',
      'cmw.svg',
      'netent.svg',
      'rich88.svg',
      'naga-games.svg',
      'spadegaming.svg',
      'cq9-gaming.svg',
    ],
  },
  {
    title: 'SPORTSBOOK',
    bgImage: '/images/sportsbook-bg.svg',
    providers: ['jade-sports.svg', 'tf-gaming.svg', 'sports-plus.svg', 'goat-sports.svg'],
  },
];

const ItemCard = ({ icon, title, content, handleModal }) => (
  <Box className="cardContainer" onClick={handleModal}>
    <Box className="content">
      <Box>{icon}</Box>
      <Typography className="contentTitle">{title}</Typography>
      <Typography className="contentDescription">{content}</Typography>
    </Box>
  </Box>
);

ItemCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  handleModal: PropTypes.func,
};

const MobileServices = () => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  // const [[imageCount, direction], setImageCount] = useState([0, 0]);

  // const activeImageIndex = wrap(0, items.length, imageCount);

  // const swipeToImage = (swipeDirection) => {
  //   setImageCount([imageCount + swipeDirection, swipeDirection]);
  // };

  // const dragEndHandler = (dragInfo) => {
  //   const draggedDistance = dragInfo.offset.x;
  //   const swipeThreshold = 50;
  //   if (draggedDistance > swipeThreshold) {
  //     swipeToImage(-1);
  //   } else if (draggedDistance < -swipeThreshold) {
  //     swipeToImage(1);
  //   }
  // };

  // const skipToImage = (imageId) => {
  //   let changeDirection;
  //   if (imageId > activeImageIndex) {
  //     changeDirection = 1;
  //   } else if (imageId < activeImageIndex) {
  //     changeDirection = -1;
  //   }
  //   setImageCount([imageId, changeDirection]);
  // };

  const handleClickOpen = (id) => {
    filterByIndex(id);
    setOpen(true);
    console.log(i18n.language);
  };
  const handleClose = () => {
    setFilteredData([]);
    setOpen(false);
  };
  const filterByIndex = (excludeIndex) => {
    const filteredData = items.filter((_, index) => index === excludeIndex);
    setFilteredData(filteredData);
  };
  return (
    <StyledIGamingServices>
      <Modal toggle={open} toggleClose={handleClose}>
        <div className="modal-provider">
          <Box
            sx={{
              // backgroundColor: '#FFFFFF',
              width: '100%',
              height: '686px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              overflowY: 'auto',
              overflowX: 'hidden',
              [theme.breakpoints.down('md')]: { height: '520px', width: '100%' },
              '@media(max-width: 425px)': { height: '420px', width: '100%' },
            }}
            className={`modal-services ${filteredData.length > 0 ? filteredData[0].title.toLowerCase() : ''}`}
          >
            <div className="inner">
              {/* <Box
                sx={{ display: 'flex', marginLeft: '60px', marginTop: '50px' }}
                className={`modalIcon-${filteredData[0]?.title.toLowerCase()}`}
                onClick={handleClose}
              >
                <img src={`/images/modal-back-icon.svg`} alt="" />
              </Box> */}
              {filteredData.map((item, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  className={`services ${item.title.toLowerCase()}`}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Box className="modalIcon">{item.icon}</Box> */}
                    <Box>
                      <img src={`/images/${item.title.toLowerCase()}-modal-icon.svg`} alt="" className="icon" />
                    </Box>
                    <Typography className="title">{t(item.title.toLowerCase())}</Typography>
                    <Typography
                      sx={{
                        color: '#FFFFFF',
                        fontWeight: '600',
                        fontSize: '18px',
                        letterSpacing: '0.2em',
                        lineHeight: '22px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {parse(t(`${item.title.toLowerCase()}Provider`))}
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                        lineHeight: '22px',
                        color: '#4B4B4B',
                        textAlign: 'center',
                      }}
                    >
                      {item.content}
                    </Typography> */}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '10px',
                      [theme.breakpoints.down('md')]: {
                        marginTop: '25px',
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height:
                          item.title.toLowerCase() === 'sportsbook'
                            ? '140px'
                            : i18n.language === 'en-US'
                              ? '339px'
                              : '310px',
                        '@media(max-width: 425px)': {
                          height:
                            item.title.toLowerCase() === 'sportsbook'
                              ? '120px'
                              : i18n.language === 'en-US'
                                ? '250px'
                                : '190px',
                        },
                      },
                    }}
                    mt={7}
                  >
                    {item.providers.map((provider, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: '#4B4B4B',
                          padding: '12px 25px',
                          borderRadius: '5px',
                          border: '1px solid #4B4B4B',
                          '@media(max-width: 425px)': { padding: '6px' },
                        }}
                      >
                        <Box
                          sx={{
                            height: '32px',
                            width: '105px',
                          }}
                        >
                          <img src={`/images/providers/${provider}`} alt="" />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {/* <Box mt={7} mb={7}>
                    <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="GET PROPOSAL" variant={COLOR.CONTAINED} />
                  </Box> */}
                </Box>
              ))}
            </div>
          </Box>
        </div>
      </Modal>
      <Box className="mobileView sectionBG">
        <img src="/images/servicesBG.svg" className="videoElement"></img>
        <Box className="sectionTitle">
          <Box>
            <Typography>
              <span className="spanTextGradient">{t('services')}</span>
            </Typography>
          </Box>
        </Box>
        <Carousel>
          {items.map((item, index) => (
            <Slide key={index}>
              <div className="Slide-content">
                <Box
                  className={item.title.toLocaleLowerCase()}
                  // sx={{
                  //   backgroundSize: '100% 100%',
                  //   backgroundRepeat: 'round',
                  //   backgroundImage: `url(${item.bgImage})`,
                  //   position: 'relative',
                  //   width: '340px',
                  // }}
                  onClick={() => handleClickOpen(index)}
                >
                  <Box
                    sx={{
                      // position: 'relative',
                      justifyContent: 'center',
                      justifyItems: 'center',
                      justifySelf: 'center',
                      display: 'flex',
                    }}
                  >
                    <img alt="Placeholder 1" src={item.bgImage} className="bgImage" />
                    <img src={`/images/${item.title.toLowerCase()}-icon.svg`} alt="" className="icon" />
                    <p className={`title-${i18n.language}`}>{t(item.title.toLowerCase())}</p>
                    <p className={`provider-${i18n.language}`}>{parse(t(`${item.title.toLowerCase()}Provider`))}</p>
                  </Box>
                </Box>
              </div>
            </Slide>
          ))}
        </Carousel>
        {/* <Box justifyContent={'center'} display={'flex'} marginTop={60}>
          <Divider
            sx={{
              height: 2,
              width: '51px',
              background:
                'linear-gradient(270deg, #FF3E8F 0%, #FE1D53 100%, #FF3E55 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
            }}
          />
          <Divider
            sx={{
              marginLeft: '10px',
              height: 2,
              width: '51px',
              background: 'linear-gradient(263.53deg, #0495FF 2.76%, #3EC5FF 97.98%)',
            }}
          />
          <Divider
            sx={{
              marginLeft: '10px',
              height: 2,
              width: '51px',
              background:
                'linear-gradient(262.31deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
            }}
          />
        </Box> */}
        <Container>
          <p className="notice">
            {t('serviceText1')}
            <br />
            {t('serviceText2')}
          </p>
        </Container>
      </Box>
    </StyledIGamingServices>
  );
};

export default MobileServices;
