import { Box } from '@mui/material';
import React from 'react';
import { S3 } from '../../config/api';
import StyledFooter from './style';

const Footer = () => {
  {
    /* Hid temporarily */
  }
  // const theme = useTheme();
  // const [hideCookie, setHideCookie] = React.useState(false);
  // const handleClose = () => {
  // setHideCookie(true);
  // };
  return (
    <StyledFooter>
      <Box className="footerLogoBox">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={`${S3}/footerLogo.mp4`} type="video/mp4" />
        </video>
      </Box>
      <Box className="copyRightBox">
        <div className="separator">Copyright @2024 JADESOFT. All Rights Reserved.</div>
      </Box>
      {/* Hid temporarily */}
      {/* <Grid
        container
        sx={{
          display: hideCookie ? 'none' : 'flex',
          flexGrow: 1,
          minHeight: '60px',
          background: theme.customGradients.primary,
        }}
      >
        <Grid size={{ xs: 12, md: 12 }}>
          <Box className="cookiesBox">
            This website uses cookies. By continuing to browse the site, you agree to our use of cookies.
            <IconButton className="closeButton" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid> */}
    </StyledFooter>
  );
};

export default Footer;
