export const DARK = {
  white: '#FFFFFF',
  black: '#000000',
  background: {
    default: '#000000',
    primary: '#010201',
    secondary: 'rgba(0, 0, 0, 0.5)',
  },
  boxShadow: {
    50: '0px 1px 4px 0px rgba(12, 12, 13, 0.05)',
    100: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
    200: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    300: '0px 4px 4px -1px rgba(12, 12, 13, 0.1)',
  },
  borderColor: {
    100: 'rgba(12, 12, 13, 0.1)',
  },
  primary: {
    50: '#14FF00',
    100: '#CCFF00',
    200: '#7DFF65',
  },
  secondary: {
    50: '#141414',
    100: '#010201',
    200: '#000000',
  },
  green: {
    // success
    50: '#35B558',
    100: '#34C759',
    200: '#34A453',
    300: '#22A852',
  },
  red: {
    // error
    50: '#C13333',
    100: '#CD352A',
    200: '#ef9a9a',
    300: '#DD392D',
  },
  yellow: {
    // warning
    50: '#F2D058',
    100: '#DE9300',
    200: '#F79400',
  },
  blue: {
    // info
    50: '#e3f2fd',
    100: '#00D1FF',
    200: '#90caf9',
  },
  grey: {
    50: '#F5F5F5',
    100: '#A3A3A3',
    200: '#BBBBBB',
  },
  gradient: {
    green: {
      50: '#14FF00',
      100: '#CCFF00',
      200: 'rgba(0,0,0,0.4)',
    },
    black: {
      50: 'rgba(255, 255, 255, 0.1)',
      100: 'rgba(0, 0, 0, 0.5)',
      200: 'rgba(0, 0, 0, 0.2)',
    },
  },
};
