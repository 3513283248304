import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const IconContract = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 24 26'}
    aria-hidden="true"
    width={width || '24'}
    height={height || '26'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.34688 2.2834H19.4896V14.0689H21.6327V0.140625H0.204102V25.8548H11.9896V23.712H2.34688V2.2834Z"
        fill="url(#paint0_linear_1407_3864)"
      />
      <path
        d="M2.34688 2.2834H19.4896V14.0689H21.6327V0.140625H0.204102V25.8548H11.9896V23.712H2.34688V2.2834Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M4.48965 4.42617H17.3469V6.56895H4.48965V4.42617ZM4.48965 8.71201H15.2038V10.8548H4.48965V8.71201ZM4.48965 12.9976H10.9183V15.1403H4.48965V12.9976ZM23.6809 24.2933L22.0347 22.6471C22.5503 21.8795 22.8523 20.9561 22.8523 19.962C22.8523 17.2992 20.6937 15.1406 18.031 15.1406C15.3682 15.1406 13.2096 17.2992 13.2096 19.962C13.2096 22.6248 15.3682 24.7834 18.031 24.7834C18.9216 24.7834 19.7454 24.525 20.4614 24.104L22.1656 25.8082L23.6809 24.2933ZM15.3526 19.962C15.3526 18.4849 16.5544 17.2834 18.0312 17.2834C19.5081 17.2834 20.7099 18.4849 20.7099 19.962C20.7099 21.4389 19.5081 22.6406 18.0312 22.6406C16.5544 22.6406 15.3526 21.4389 15.3526 19.962Z"
        fill="url(#paint1_linear_1407_3864)"
      />
      <path
        d="M4.48965 4.42617H17.3469V6.56895H4.48965V4.42617ZM4.48965 8.71201H15.2038V10.8548H4.48965V8.71201ZM4.48965 12.9976H10.9183V15.1403H4.48965V12.9976ZM23.6809 24.2933L22.0347 22.6471C22.5503 21.8795 22.8523 20.9561 22.8523 19.962C22.8523 17.2992 20.6937 15.1406 18.031 15.1406C15.3682 15.1406 13.2096 17.2992 13.2096 19.962C13.2096 22.6248 15.3682 24.7834 18.031 24.7834C18.9216 24.7834 19.7454 24.525 20.4614 24.104L22.1656 25.8082L23.6809 24.2933ZM15.3526 19.962C15.3526 18.4849 16.5544 17.2834 18.0312 17.2834C19.5081 17.2834 20.7099 18.4849 20.7099 19.962C20.7099 21.4389 19.5081 22.6406 18.0312 22.6406C16.5544 22.6406 15.3526 21.4389 15.3526 19.962Z"
        fill="black"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1407_3864"
          x1="23.6809"
          y1="0.140622"
          x2="-2.56836"
          y2="3.37847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1407_3864"
          x1="23.6809"
          y1="0.140622"
          x2="-2.56836"
          y2="3.37847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

IconContract.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconContract;
