import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { POSITION, VARIANT_TYPOGRAPHY } from '../../enums';
import StyledTypography from './styles';

const Typography = ({
  color,
  fontWeight = 500,
  label,
  textAlign = POSITION.LEFT,
  textTransform,
  variant = VARIANT_TYPOGRAPHY.H6,
  whiteSpace = 'nowrap',
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <StyledTypography
      variant={variant}
      color={color}
      fontWeight={fontWeight}
      textAlign={textAlign}
      textTransform={textTransform}
      whiteSpace={whiteSpace}
      {...rest}
    >
      {t(label)}
    </StyledTypography>
  );
};

Typography.propTypes = {
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  label: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  variant: PropTypes.string,
  whiteSpace: PropTypes.string,
};

export default Typography;
