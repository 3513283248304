import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMainMenu = styled(Box)(({ theme }) => ({
  minHeight: '130px',
  height: '100%',
  width: '100%',
  maxHeight: '130px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'sticky',
  top: '80px',
  zIndex: 1300,
  opacity: 1,

  '& .mainMenuContainer': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    // background:
    //   'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
    backdropFilter: 'blur(100px)', // Optional: adds blur effect
    WebkitBackdropFilter: 'blur(100px)', // Safari compatibility
    boxShadow: '0px 4px 4px -1px rgba(12, 12, 13, 0.05), 0px 4px 4px -1px rgba(12, 12, 13, 0.0)', // TODO: move to theme
    // boxShadow: theme.palette.boxShadow.secondary,
    borderRadius: '100px',
    minHeight: '130px',
    maxHeight: '130px',

    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   borderRadius: '100px',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   backgroundColor: 'rgba(0, 0, 0, 1)', // Fully opaque
    //   backdropFilter: 'blur(100px)', // Optional: adds blur effect
    //   WebkitBackdropFilter: 'blur(100px)', // Safari compatibility
    //   zIndex: -1, // Ensure it sits behind the AppBar content
    // },

    '& .mainMenu': {
      position: 'relative',
      overflow: 'hidden',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '70px',

      // show underline upon hover
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0%',
        height: '2px',
        background:
          'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%),linear-gradient(262deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%)',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'width 1s ease',
        zIndex: 9,
      },
      '&::before': {
        top: '0', // Top border animation
        bottom: 'auto',
      },
      '&:hover::after': {
        width: '70%', // Extend line on hover
        boxShadow: '0px 0px 50px 3px rgba(96, 210, 7, 1)',
      },
      '&:hover': {
        '& .mainMenuIcon': {
          // Animation on Icon Service upon hover
          '& g#iconService': {
            animation: 'rotateCounterClockwiseIconService 1s ease-in',
            transformOrigin: 'center',
            transformBox: 'fill-box',
            transition: 'transform 1s ease',
          },
          '@keyframes rotateCounterClockwiseIconService': {
            '0%': {
              transform: 'rotateZ(0deg)',
            },
            '100%': {
              transform: 'rotateZ(-180deg)',
            },
          },

          // Animation on Icon Function upon hover
          '& g#iconFunction': {
            '& g#beta': {
              transform: 'translate(-20px, -20px)',
            },
            '& g#charlie': {
              transform: 'translate(21px, 18px)',
            },
          },
        },

        '& .mainMenuText': {
          cursor: 'pointer',
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%),linear-gradient(262deg, #12CF01 0%, #42FF00 50%, #CCFF00 100%)',
          backgroundClip: 'text',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
        },
      },

      '& .mainMenuIcon': {
        height: '45px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',

        '& g#iconService': {
          animation: 'rotateClockwiseIconService 1s ease-in',
          transformOrigin: 'center',
          transformBox: 'fill-box',
          transition: 'transform 1s ease',
        },
        '@keyframes rotateClockwiseIconService': {
          '0%': {
            transform: 'rotateZ(0deg)',
          },
          '100%': {
            transform: 'rotateZ(180deg)',
          },
        },
      },
    },
  },

  '& .MuiGrid2-root': {
    justifyContent: 'center',
  },

  [theme.breakpoints.down('md')]: {
    zIndex: -10,
    position: 'fixed',
    top: 0,
    height: '0',
    marginTop: '-100px',
  },
}));

export default StyledMainMenu;
