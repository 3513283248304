import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import { I18nextProvider } from 'react-i18next';

import i18n from '../../translations/i18n';

const LoadingPage = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{ background: (theme) => theme.palette.background.default }}
      >
        <CircularProgress />
      </Box>
    </I18nextProvider>
  );
};

export default LoadingPage;
