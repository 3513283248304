import PropTypes from 'prop-types';
import React from 'react';

import { Box, Menu, MenuItem } from '@mui/material';

import IconButton from '../../components/IconButton';
import IconGlobe from '../../components/Icons/IconGlobe';

const LanguageSettings = ({ options, onChangeLanguage, toggleClose, toggleOpen, toggle }) => {
  return (
    <Box>
      <IconButton Icon={IconGlobe} onClick={toggleOpen} />
      <Menu
        id="basic-menu"
        anchorEl={toggle}
        open={Boolean(toggle)}
        onClose={toggleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((item, index) => (
          <Box key={index}>
            <MenuItem onClick={() => onChangeLanguage(item.lang)}>{item.name}</MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  );
};

LanguageSettings.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      lang: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onChangeLanguage: PropTypes.func,
  toggleClose: PropTypes.func,
  toggleOpen: PropTypes.func,
  toggle: PropTypes.object,
};

export default LanguageSettings;
