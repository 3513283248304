import { useState } from 'react';

export default function useToggle({ toggleList }) {
  const [toggle, setToggle] = useState(toggleList);

  const onToggle = (id, value) => {
    setToggle((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // For Boolean
  const toggleOpen = (id) => onToggle(id, true);
  const toggleClose = (id) => onToggle(id, false);

  // For Nullable
  const toggleClickOpen = (event, id) => {
    onToggle(id, event.currentTarget);
  };
  const toggleClickClose = (id) => onToggle(id, null);

  return { onToggle, toggle, toggleOpen, toggleClose, toggleClickOpen, toggleClickClose };
}
