import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'start !important',
    justifyContent: 'end',
    marginTop: '80px',
    // marginRight: '10px',
    transition: 'transform 225ms cubic-bezier(0.42, 0, 1, 1) !important',

    '& .modalIcon': {
      '& svg': {
        height: '83px',
        width: '83px',
      },
    },

    '& .MuiPaper-root': {
      // Avoid padding here to make it more dynamic style for all components
      minWidth: '1200px',
      background: 'transparent',
      margin: '0px auto',
      transition: 'box-shadow 200ms cubic-bezier(0.4, 0.4, 0.2, 0) 1ms',
      borderRadius: '0px',

      '& .MuiDialogContent-root': {
        maxHeight: '686px',
        overflowY: 'auto',
        padding: '0px',
        overflowX: 'hidden',
      },

      [theme.breakpoints.down('md')]: {
        minWidth: '100%',
        width: '100%',
        minHeight: '30vh',

        '& .MuiDialogContent-root': {
          minHeight: '200px',
        },
      },
    },

    [theme.breakpoints.down('md')]: {
      alignItems: 'end',
      position: 'absolute',
      top: '15%',
    },
  },
}));

export default StyledDialog;
