import React, { useEffect } from 'react';

import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';

import LoadingPage from '../components/Loading/LoadingPage';
import createEmotionCache from './createEmotionCache';
import './index.css';

import { theme } from '../config/theme';
import { registerServiceWorker } from './listener';
import { router } from './router';

const cache = createEmotionCache();

export default function App() {
  // set the default theme to dark mode
  useEffect(() => {
    const muiMode = localStorage.getItem('mui-mode');
    if (muiMode === 'system') {
      localStorage.setItem('mui-mode', 'dark');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} fallbackElement={<LoadingPage />} />
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline
            to build upon. */}
        <CssBaseline />
      </ThemeProvider>
    </CacheProvider>
  );
}
