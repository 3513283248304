/* eslint-disable */
import { Box, Container, Grid2, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Dialog';
import MobileServices from './mobile';
import StyledIGamingServices from './styles';

const items = [
  {
    title: 'CASINO',
    providers: [
      'evolution-gaming.svg',
      'pragmatic-play-casino.svg',
      'microgaming.svg',
      'mg-live.svg',
      'imagine-live.svg',
      'asia-gaming.svg',
      'ho-gaming.svg',
      'better-live.svg',
      'betgames.svg',
      'pretty-gaming.svg',
      'wm-casino.svg',
      'dream-gaming.svg',
      'via-casino.svg',
      'sexy-gaming.svg',
      'vivo.svg',
      'infinity.svg',
      'world-entertainment.svg',
      'yeebet-gaming.svg',
    ],
  },
  {
    title: 'SLOT',
    providers: [
      'bolebit.svg',
      'red-tiger.svg',
      'amigo-gaming.svg',
      'joker.svg',
      'jili.svg',
      'simpleplay.svg',
      'fa-chai.svg',
      'habanero.svg',
      'ka-gaming.svg',
      'pragmatic-play.svg',
      'cmw.svg',
      'netent.svg',
      'rich88.svg',
      'naga-games.svg',
      'spadegaming.svg',
      'cq9-gaming.svg',
    ],
  },
  {
    title: 'SPORTSBOOK',
    providers: ['jade-sports.svg', 'tf-gaming.svg', 'sports-plus.svg', 'goat-sports.svg'],
  },
];

const ItemCard = ({ icon, title, content, handleModal }) => (
  <Box className="cardContainer" onClick={handleModal}>
    <Box className="content">
      <Box>{icon}</Box>
      <Typography className="contentTitle">{title}</Typography>
      <Typography className="contentDescription">{content}</Typography>
    </Box>
  </Box>
);

ItemCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  handleModal: PropTypes.func,
};

const IGamingServices = () => {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 899.96) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);
  const handleClickOpen = (id) => {
    filterByIndex(id);
    setOpen(true);
  };
  const handleClose = () => {
    setFilteredData([]);
    setOpen(false);
  };
  const filterByIndex = (excludeIndex) => {
    const filteredData = items.filter((_, index) => index === excludeIndex);
    setFilteredData(filteredData);
  };
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const [changeOpacity, setChangeOpacity] = useState(false);

  return (
    <StyledIGamingServices>
      {isMobile ? (
        <MobileServices />
      ) : (
        <>
          <Box className={`circleContainer ${changeOpacity ? 'fadeIn' : 'fadeOut'}`}>
            <Box className="circle1"></Box>
            <Box className="circle2"></Box>
            <Box className="circle3"></Box>
            <Box className="circle4"></Box>
          </Box>
          <Modal toggle={open} toggleClose={handleClose}>
            <div className="modal-provider">
              <Box
                sx={{
                  // backgroundColor: '#FFFFFF',
                  width: '100%',
                  height: '686px',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  [theme.breakpoints.down('md')]: { height: '520px', width: '100%' },
                }}
                className={`modal-services ${filteredData.length > 0 ? filteredData[0].title.toLowerCase() : ''}`}
              >
                <div className="inner">
                  <Box
                    sx={{ display: 'flex', marginLeft: '60px', marginTop: '50px' }}
                    className={`modalIcon-${filteredData[0]?.title.toLowerCase()}`}
                    onClick={handleClose}
                  >
                    <img src={`/images/modal-back-icon.svg`} alt="" />
                  </Box>
                  {filteredData.map((item, index) => (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                      className={`services ${item.title.toLowerCase()}`}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <Box className="modalIcon">{item.icon}</Box> */}
                        <Box>
                          <img src={`/images/${item.title.toLowerCase()}-modal-icon.svg`} alt="" className="icon" />
                        </Box>
                        <Typography className="title">{t(item.title.toLowerCase())}</Typography>
                        <Typography
                          sx={{
                            color: '#FFFFFF',
                            fontWeight: '600',
                            fontSize: '18px',
                            letterSpacing: '0.2em',
                            lineHeight: '22px',
                            textTransform: 'uppercase',
                          }}
                        >
                          {t(`${item.title.toLowerCase()}ProviderModal`)}
                        </Typography>
                        {/* <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                        lineHeight: '22px',
                        color: '#4B4B4B',
                        textAlign: 'center',
                      }}
                    >
                      {item.content}
                    </Typography> */}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          gap: '10px',
                          [theme.breakpoints.down('md')]: {
                            marginTop: '25px',
                            overflow: 'hidden',
                            overflowY: 'scroll',
                            height: item.title.toLowerCase() === 'sportsbook' ? '140px' : '339px',
                          },
                        }}
                        mt={7}
                      >
                        {item.providers.map((provider, index) => (
                          <Box
                            sx={{
                              backgroundColor: '#4B4B4B',
                              padding: '12px 25px',
                              borderRadius: '5px',
                              border: '1px solid #4B4B4B',
                              '@media(max-width: 425px)': { padding: '6px' },
                            }}
                          >
                            <Box
                              sx={{
                                height: '32px',
                                width: '105px',
                              }}
                            >
                              <img src={`/images/providers/${provider}`} alt="" />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      {/* <Box mt={7} mb={7}>
                    <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="GET PROPOSAL" variant={COLOR.CONTAINED} />
                  </Box> */}
                    </Box>
                  ))}
                </div>
              </Box>
            </div>
          </Modal>
          <Box className="sectionBG">
            {/* <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={SectionBG} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
            {/* <img src="/images/servicesBG.svg" className="videoElement"></img> */}
            <Box className="sectionTitle">
              <Box>
                <motion.div
                  className="card"
                  initial={{
                    opacity: 0,
                    y: -70,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 2, // Animation duration
                    },
                  }}
                  // viewport={{ once: true }}
                >
                  <Typography>
                    <span className="spanTextGradient">{t('services')}</span>
                  </Typography>
                </motion.div>
              </Box>
            </Box>
            {/* <Container>
          <Grid2 container spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
            <Box className="sectionDescription">
              <Typography className="sectionDescriptionText">
                Flexible automation system (manual support) if you don't have an automated system, it will interfere
                with operation. However, all automation causes discord with members.
              </Typography>
            </Box>
            <Box className="sectionDescription2">
              <Typography className="sectionDescriptionTextGreen">
                Jade Soft is a system that catches both rabbits with automation/manual support.
              </Typography>
            </Box>
          </Grid2>
        </Container> */}
            <Container>
              <Grid2 container spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                {items.map((item, index) => (
                  <Grid2
                    size={{ xs: 12, sm: 6, md: 6, lg: 4 }}
                    key={index}
                    className={`services ${item.title.toLowerCase()}`}
                    onClick={() => handleClickOpen(index)}
                  >
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 200 }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          delay: index * 0.5,
                          duration: 2, // Animation duration
                        },
                      }}
                      onViewportEnter={() => setChangeOpacity(true)}
                      onViewportLeave={() => setChangeOpacity(false)}
                    >
                      <div className="inner">
                        <div
                          className="icon-container"
                          style={{ marginTop: i18n.language === 'en-US' ? '-25px' : '-35px' }}
                        >
                          <img src={`/images/${item.title.toLowerCase()}-icon.svg`} alt="" className="icon" />
                        </div>
                        <div className="text">
                          <p className="title">{t(item.title.toLowerCase())}</p>
                          <p className="provider">{parse(t(`${item.title.toLowerCase()}Provider`))}</p>
                          <img
                            src={`/images/${item.title.toLowerCase()}-arrow-down.svg`}
                            alt=""
                            className="arrow-down"
                          />
                        </div>
                        <div className="providers">
                          <Grid2
                            sx={{
                              marginTop:
                                i18n.language === 'ko-KR'
                                  ? index === 0
                                    ? '1.5px'
                                    : index === 1
                                      ? '2px'
                                      : '30px'
                                  : index === 0
                                    ? '11px'
                                    : index === 1
                                      ? '2px'
                                      : '30px',
                            }}
                            spacing={{ lg: 1 }}
                            container
                            fluid
                          >
                            {item.providers.map((logo, idx) => {
                              return (
                                <Grid2
                                  size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                                  className={`provider-icon-${item.title === 'SPORTSBOOK' ? idx + 1 : idx % 2 === 0 ? 'left' : 'right'}`}
                                  key={idx}
                                >
                                  <div className="provider-icon">
                                    <img src={`/images/providers/${logo}`} alt="" />
                                  </div>
                                </Grid2>
                              );
                            })}
                          </Grid2>
                        </div>
                      </div>
                    </motion.div>
                  </Grid2>
                ))}
              </Grid2>
            </Container>
            <Container>
              <p className="notice">
                {t('serviceText1')}
                <br />
                {t('serviceText2')}
              </p>
            </Container>
          </Box>
        </>
      )}
    </StyledIGamingServices>
  );
};

export default IGamingServices;
