import PropTypes from 'prop-types';
import React from 'react';

import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';

const Cashback = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 32 32'}
    aria-hidden="true"
    width={width || '32'}
    height={height || '32'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit',
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1008_11277)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4354 18.7894C35.5726 18.7894 34.71 19.1134 34.0602 19.7631L30.1181 23.7079C29.6815 23.0186 29.0018 22.4813 28.1628 22.2565L18.8586 19.7631C18.1171 19.5645 17.3504 19.5271 16.6068 19.6398C15.8632 19.7525 15.1434 20.0151 14.494 20.4245L8.68579 24.0884L8.216 23.2722C7.63091 22.2588 6.28725 21.8997 5.27385 22.4848L0.856691 25.0333C-0.156708 25.6184 -0.515763 26.962 0.0693194 27.9754L6.65175 39.374C7.23683 40.3874 8.57787 40.749 9.59127 40.164L14.0084 37.6129C15.0218 37.0278 15.3835 35.6841 14.7984 34.6707L14.7118 34.5185L17.2287 32.8178L26.4882 34.6392C27.7572 34.8887 28.8915 34.5347 29.7952 33.6104C32.7817 30.5556 35.7721 27.5147 38.8053 24.5215C38.808 24.5197 38.8107 24.5179 38.8134 24.5161C40.113 23.2165 40.113 21.0626 38.8134 19.763C38.1636 19.1132 37.2983 18.7894 36.4354 18.7894ZM36.4354 21.4534C36.603 21.4534 36.7706 21.5235 36.9105 21.6633C37.1901 21.943 37.1901 22.3364 36.9105 22.6161C33.8607 25.6258 30.8616 28.6781 27.8741 31.7338C27.8192 31.79 27.0844 32.0166 27.0079 32.0015L17.1999 30.0751C17.0259 30.0407 16.8468 30.041 16.6729 30.0762C16.499 30.1113 16.3338 30.1805 16.1868 30.2798L13.3654 32.1879L10.0322 26.4164L15.927 22.7C16.5932 22.28 17.4024 22.155 18.1631 22.3588L27.4672 24.8522C27.8492 24.9546 28.0446 25.2943 27.9423 25.6763C27.8399 26.0583 27.5001 26.2537 27.1182 26.1514L21.2785 24.5871C20.9343 24.4949 20.5676 24.5432 20.259 24.7214C19.9504 24.8996 19.7252 25.193 19.6329 25.5372C19.5407 25.8814 19.589 26.2481 19.7672 26.5567C19.9454 26.8653 20.2388 27.0905 20.583 27.1828L26.4227 28.7471C27.734 29.0984 29.0925 28.6009 29.9055 27.6027C30.0354 27.5433 30.155 27.4636 30.2598 27.3665L35.9604 21.6633C36.1002 21.5235 36.2678 21.4534 36.4354 21.4534ZM6.15571 25.0779L6.8066 26.2065C6.82957 26.3971 6.89311 26.5805 6.99294 26.7445C7.07914 26.8752 7.18751 26.9897 7.31314 27.0831L12.2027 35.5526L8.71204 37.5683L2.66502 27.0936L6.15571 25.0779Z"
          fill="url(#paint0_linear_1008_11277)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4354 18.7894C35.5726 18.7894 34.71 19.1134 34.0602 19.7631L30.1181 23.7079C29.6815 23.0186 29.0018 22.4813 28.1628 22.2565L18.8586 19.7631C18.1171 19.5645 17.3504 19.5271 16.6068 19.6398C15.8632 19.7525 15.1434 20.0151 14.494 20.4245L8.68579 24.0884L8.216 23.2722C7.63091 22.2588 6.28725 21.8997 5.27385 22.4848L0.856691 25.0333C-0.156708 25.6184 -0.515763 26.962 0.0693194 27.9754L6.65175 39.374C7.23683 40.3874 8.57787 40.749 9.59127 40.164L14.0084 37.6129C15.0218 37.0278 15.3835 35.6841 14.7984 34.6707L14.7118 34.5185L17.2287 32.8178L26.4882 34.6392C27.7572 34.8887 28.8915 34.5347 29.7952 33.6104C32.7817 30.5556 35.7721 27.5147 38.8053 24.5215C38.808 24.5197 38.8107 24.5179 38.8134 24.5161C40.113 23.2165 40.113 21.0626 38.8134 19.763C38.1636 19.1132 37.2983 18.7894 36.4354 18.7894ZM36.4354 21.4534C36.603 21.4534 36.7706 21.5235 36.9105 21.6633C37.1901 21.943 37.1901 22.3364 36.9105 22.6161C33.8607 25.6258 30.8616 28.6781 27.8741 31.7338C27.8192 31.79 27.0844 32.0166 27.0079 32.0015L17.1999 30.0751C17.0259 30.0407 16.8468 30.041 16.6729 30.0762C16.499 30.1113 16.3338 30.1805 16.1868 30.2798L13.3654 32.1879L10.0322 26.4164L15.927 22.7C16.5932 22.28 17.4024 22.155 18.1631 22.3588L27.4672 24.8522C27.8492 24.9546 28.0446 25.2943 27.9423 25.6763C27.8399 26.0583 27.5001 26.2537 27.1182 26.1514L21.2785 24.5871C20.9343 24.4949 20.5676 24.5432 20.259 24.7214C19.9504 24.8996 19.7252 25.193 19.6329 25.5372C19.5407 25.8814 19.589 26.2481 19.7672 26.5567C19.9454 26.8653 20.2388 27.0905 20.583 27.1828L26.4227 28.7471C27.734 29.0984 29.0925 28.6009 29.9055 27.6027C30.0354 27.5433 30.155 27.4636 30.2598 27.3665L35.9604 21.6633C36.1002 21.5235 36.2678 21.4534 36.4354 21.4534ZM6.15571 25.0779L6.8066 26.2065C6.82957 26.3971 6.89311 26.5805 6.99294 26.7445C7.07914 26.8752 7.18751 26.9897 7.31314 27.0831L12.2027 35.5526L8.71204 37.5683L2.66502 27.0936L6.15571 25.0779Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1211 10.8868C24.7647 10.8868 24.4229 11.0284 24.1709 11.2804C23.9189 11.5324 23.7773 11.8742 23.7773 12.2306C23.7773 12.587 23.9189 12.9288 24.1709 13.1808C24.4229 13.4328 24.7647 13.5744 25.1211 13.5744H27.8087C28.1651 13.5744 28.5069 13.4328 28.7589 13.1808C29.0109 12.9288 29.1525 12.587 29.1525 12.2306C29.1525 11.8742 29.0109 11.5324 28.7589 11.2804C28.5069 11.0284 28.1651 10.8868 27.8087 10.8868H25.1211Z"
          fill="url(#paint1_linear_1008_11277)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1211 10.8868C24.7647 10.8868 24.4229 11.0284 24.1709 11.2804C23.9189 11.5324 23.7773 11.8742 23.7773 12.2306C23.7773 12.587 23.9189 12.9288 24.1709 13.1808C24.4229 13.4328 24.7647 13.5744 25.1211 13.5744H27.8087C28.1651 13.5744 28.5069 13.4328 28.7589 13.1808C29.0109 12.9288 29.1525 12.587 29.1525 12.2306C29.1525 11.8742 29.0109 11.5324 28.7589 11.2804C28.5069 11.0284 28.1651 10.8868 27.8087 10.8868H25.1211Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1211 5.51172C24.7647 5.51172 24.4229 5.65327 24.1709 5.90528C23.9189 6.15729 23.7773 6.49911 23.7773 6.8555C23.7773 7.21189 23.9189 7.55367 24.1709 7.80568C24.4229 8.05769 24.7647 8.19928 25.1211 8.19928H27.8087C28.1651 8.19928 28.5069 8.05769 28.7589 7.80568C29.0109 7.55367 29.1525 7.21189 29.1525 6.8555C29.1525 6.49911 29.0109 6.15729 28.7589 5.90528C28.5069 5.65327 28.1651 5.51172 27.8087 5.51172H25.1211Z"
          fill="url(#paint2_linear_1008_11277)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1211 5.51172C24.7647 5.51172 24.4229 5.65327 24.1709 5.90528C23.9189 6.15729 23.7773 6.49911 23.7773 6.8555C23.7773 7.21189 23.9189 7.55367 24.1709 7.80568C24.4229 8.05769 24.7647 8.19928 25.1211 8.19928H27.8087C28.1651 8.19928 28.5069 8.05769 28.7589 7.80568C29.0109 7.55367 29.1525 7.21189 29.1525 6.8555C29.1525 6.49911 29.0109 6.15729 28.7589 5.90528C28.5069 5.65327 28.1651 5.51172 27.8087 5.51172H25.1211Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4651 0.136597C21.2859 0.136597 17.0586 4.36396 17.0586 9.54307C17.0586 14.7222 21.2859 18.9495 26.4651 18.9495C31.6442 18.9495 35.8715 14.7222 35.8715 9.54307C35.8715 4.36396 31.6442 0.136597 26.4651 0.136597ZM26.4651 2.82416C30.1917 2.82416 33.184 5.81641 33.184 9.54307C33.184 13.2697 30.1917 16.262 26.4651 16.262C22.7384 16.262 19.7462 13.2697 19.7462 9.54307C19.7462 5.81641 22.7384 2.82416 26.4651 2.82416Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1008_11277"
          x1="39.7881"
          y1="18.7893"
          x2="-2.9542"
          y2="29.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1008_11277"
          x1="29.1525"
          y1="10.8868"
          x2="23.4663"
          y2="12.4233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1008_11277"
          x1="29.1525"
          y1="5.51172"
          x2="23.4663"
          y2="7.04819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12CF01" />
          <stop offset="0.5" stopColor="#42FF00" />
          <stop offset="1" stopColor="#CCFF00" />
        </linearGradient>
        <clipPath id="clip0_1008_11277">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

Cashback.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Cashback;
